<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.name">- {{ dataSet.name }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges(dataRef ? dataRef : dataSet.id)">Save Changes</button>

        <router-link :to="{ name: 'Pages' }" title="All Pages" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex page-editor" v-else>
      <div class="body-left three">

        <ValidationObserver ref="dataForm">
          <div class="page-form">

            <div class="form-section">
              <h3>Page Content</h3>

              <div class="form-group">
                <label for="title">Title<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="title" v-model="dataSet.title" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="subtitle">Subtitle</label>
                <input type="text" id="subtitle" v-model="dataSet.subtitle" />
              </div>

              <div class="form-group" v-if="!dataRef">
                <label for="dataRef">URL Slug<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="dataRef" v-model="dataSet.id" :disabled="dataRef" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="descriptionField">Page Body Content</label>
                <wysiwyg-editor
                  id="descriptionField"
                  v-model="dataSet.content"
                  class="html-editor page"
                  :api-key="tinyApi"
                  toolbar="undo redo | styleselect | bold italic underline forecolor | code | alignleft aligncenter alignright alignjustify | numlist bullist | fullscreen | link image media | preview"
                  plugins="autolink code fullscreen image link media template charmap anchor insertdatetime advlist lists wordcount imagetools textpattern noneditable preview"
                  :init="{
                    menubar: false,
                    inline: true,
                    setup: function (editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>

            <div v-if="dataSet.id === 'contact'" class="form-section">
              <div class="content-panel" v-if="dataSet.faqs.length > 0">
                <div class="panel-title">
                  <h3>Frequently Asked Questions</h3>
                </div>

                <div class="panel-body no-padding">
                  <div class="inline-table">
                    <vue-good-table
                    :columns="faqColumns"
                    :rows="dataSet.faqs"
                    styleClass="vgt-table"
                    :sort-options="{ enabled: false }"
                    :search-options="{ enabled: false }"
                    :pagination-options="{ enabled: false }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'question'">
                        {{ props.row.question.substring(0,50) }}...
                      </span>
                      <span v-else-if="props.column.field == 'answer'">
                        <span v-html="props.row.answer.substring(0,50)"></span>
                      </span>
                      <span v-else-if="props.column.field == 'actions'">
                        <div class="btn-set">
                          <button class="btn btn-sm btn-icon" @click.prevent="editFaq(props.index)">
                            <i class="fal fa-edit"></i>
                          </button>
                          <button class="btn btn-sm btn-sec btn-icon btn-delete" @click="deleteFaq(props.index)">
                            <i class="fal fa-trash-alt"></i>
                          </button>
                        </div>
                      </span>
                    </template>
                  </vue-good-table>
                  </div>
                </div>
              </div>

              <button class="btn btn-sec" @click="viewFaqPanel()" title="Add an FAQ">Add an FAQ</button>

            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="sidebar-toggle">
        <button 
          @click="showSidebar = !showSidebar" 
          :title="showSidebar ? 'Hide Sidebar' : 'Show Sidebar'" 
          class="btn btn-text menu-toggle" 
          v-tooltip="showSidebar ? 'Hide Sidebar' : 'Show Sidebar'">
          <i class="fal fa-arrow-circle-right" v-if="showSidebar"></i>
          <i class="fal fa-arrow-circle-left" v-else></i>
        </button>
      </div>

      <aside class="body-right one hideable" v-if="showSidebar">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Header Image</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="headerType" v-tooltip="'Choose between a larger Hero Header or Text-based Header'">Header Format</label>
              <v-select
                id="headerType"
                v-model="dataSet.headerType"
                :options="['Hero Header', 'Text Header']">
              </v-select>
            </div>
            <file-uploader v-on:downloadURL="getImageUrl" :parentContext="`${storageContext}/pages/${dataSet.id}/`" :oldImgUrl="dataSet.imageUrl"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Page Display Settings</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="layout">Page Width</label>
              <v-select
                id="layout"
                v-model="dataSet.layout"
                :options="['Full Width', 'Narrow']">
              </v-select>
            </div>

            <div class="checkbox-group">
              <label for="showFeaturedEvents">
                <input type="checkbox" v-model="dataSet.showFeaturedEvents" id="showFeaturedEvents" />
                Show Featured Events on Page
              </label>

              <label for="showFeaturedPromos">
                <input type="checkbox" v-model="dataSet.showFeaturedPromotions" id="showFeaturedPromos" />
                Show Featured Promotions on Page
              </label>
            </div>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>SEO Settings</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="indexPage">
                <input type="checkbox" v-model="dataSet.index" id="indexPage" />
                Index this Page
              </label>
            </div>

            <div class="form-group">
              <label for="seoTitle">Meta Title</label>
              <input type="text" id="seoTitle" v-model="dataSet.seoTitle" />
            </div>

            <div class="form-group">
              <label for="seoDescription">Meta Description</label>
              <textarea id="seoDescription" v-model="dataSet.seoDescription"></textarea>
            </div>
          </div>
        </div>

        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Page
          </button>
        </div>
      </aside>
    </div>


  </div>
</template>

<script>
import ContactFaq from '@/components/ContactFaq'
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/themes/prism-tomorrow.css';
import PurgeApi from "@/services/admin/purgeApi";

export default {
  name: 'Page',
  data() {
    return {
      purgeApi: new PurgeApi(),
      dataRef: this.$route.params.ref,
      dataSet: { 
        display: {}
      },
      showSidebar: true,
      loading: false,
      newFaq:{},
      faqColumns: [
        {
          label: 'Question',
          field: 'question',
          tdClass: 'title-col',
        },
        {
          label: 'Answer',
          field: 'answer'
        },
        {
          label: '',
          field: 'actions',
          sortable: false,
          tdClass: 'actions-col',
        }
      ]
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Page'
      } else {
        return 'Add a Page'
      }
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader,
    'code-editor': PrismEditor,
  },

  async created() {
    let self = this;
    if(self.dataRef) {
      self.getData();
    }

  },

  methods: {
    getData: async function() {
      let self = this;
      self.loading = true;
      let dataSet = await self.db.collection('pages').doc(self.dataRef).get();
      self.dataSet = dataSet.data();
      self.loading = false;
    },

    saveChanges: async function(ref) {
      let self = this;

      let success = await self.$refs.dataForm.validate();

      if(success) {
        delete self.dataSet.addFaqEventHandler;
        if(self.dataRef) {
          try {
            await self.db.collection('pages').doc(ref).update(self.dataSet);
            self.$notify({
              group: 'saved',
              title: 'Page Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            self.dataSet.name = self.dataSet.title;
            await self.db.collection('pages').doc(ref).set(self.dataSet)
            self.$notify({
              group: 'saved',
              title: 'Page Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/pages/' + ref });
            await self.getData();
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Page - ' + error,
              type: 'error'
            });
          }
        }
        await self.purgeApi.purgeUrl(self.getCurrentPartner, `/${self.dataRef}`);
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this comic?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('venues').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Page Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Pages'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getImageUrl: function (url) {
      let self = this;
      self.dataSet.imageUrl = url
    },

    highlighter: function(code) {
      return highlight(code, languages.js);
    },

    addFaq(faqData) {
      let self = this;
      self.newFaq = faqData;
      self.dataSet.faqs.push(self.newFaq);
      self.newFaq = {}
      self.showFormErrors = false;
    },

    editFaq: function(index) {
      let self = this;
      self.showPanel(ContactFaq, self.dataSet, index);
    },

    deleteFaq: function(index) {
      let self = this;

      if (confirm('Are you sure you want to delete this faq?')) {
        self.dataSet.faqs.splice(index, 1);
      }
    },

    viewFaqPanel: function() {
      let self = this;
      self.dataSet.addFaqEventHandler = self.addFaq;
      self.showPanel(ContactFaq, self.dataSet);
    },

    showPanel: function(component, flyoutData, itemIndex, closeCallback, panelProperties) {
      let self = this;

      self.panelResult = self.$showPanel({
        'component': component,
        props: {
          flyoutData,
          itemIndex
        },
        openOn: 'right',
        disableBgClick: false,
        ...panelProperties
      });

      self.panelResult.promise
      .then(result => {
        if(closeCallback) {
          closeCallback(result);
        }
      });
    },

  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Pages' });

    }
  }
}
</script>
