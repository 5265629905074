<template>
  <div class="page-body" :key="componentKey">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges()" :disabled="saving">
          <span v-if="!saving">Save</span>
          <span v-else><i class="fal fa-spinner-third fa-spin"></i></span>
        </button>

        <router-link :to="{ name: 'Orders' }" title="All Orders" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">
        <!-- Order Fields -->
        <ValidationObserver ref="ordersForm">
          <div class="data-form">
            <h2>Event Information</h2>

            <!-- Event Fields -->
            <div class="form-section" v-if="!dataRef">
              <div class="form-group">
                <label for="orderEvent">Event<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    :reduce="event => event.id"
                    v-model="dataSet.eventId"
                    :options="currentEventList"
                    @input="selectEvent"
                    placeholder="- Select an Event -"
                    label="name"
                    id="orderEvent"
                    name="orderEvent">

                    <template v-slot:option="option">
                      {{ option.name }}
                      ({{ option.startDate | moment("ddd, MMM Do, YYYY")  }} - {{option.endDate | moment("ddd, MMM Do, YYYY") }})
                    </template>

                    <template v-slot:selected-option="{ name, startDate, endDate }">
                      {{ name }}
                      ({{ startDate | moment("ddd, MMM Do, YYYY")  }} - {{ endDate | moment("ddd, MMM Do, YYYY") }})
                    </template>
                  </v-select>

                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="two-col-form">
                <div class="form-group" v-if="showList && showList.length">
                  <label for="orderShow">Show<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      :reduce="show => show.id"
                      v-model="dataSet.showId"
                      :options="showList"
                      @input="selectShow"
                      placeholder="- Select a Show -"
                      label="date"
                      id="orderShow">

                      <template v-slot:option="option">
                        {{ option.date | moment("ddd, MMM Do, h:mma")  }}
                      </template>

                      <template v-slot:selected-option="{date}">
                        {{ date | moment("ddd, MMM Do, h:mma")  }}
                      </template>
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>



                <div class="form-group" v-if="dataSet.showId">
                  <label for="orderTickets">Number of Tickets<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="dataSet.numberOfTickets"
                      :options="numberOfTickets"
                      id="orderTickets">
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!-- Completed Order Summary Fields -->
            <div class="form-section" v-else>
              <div class="two-col-form">
                <div class="form-group no-fields">
                  <p>
                    <strong>Event:</strong>
                    {{ event.name }}
                  </p>

                  <p>
                    <strong>Show:</strong>
                    {{ show.date | moment("ddd, MMM Do, YYYY h:mma") }}
                  </p>

                  <p>
                    <strong>Number of Tickets:</strong>
                    {{ dataSet.numberOfTickets }}
                  </p>
                </div>

                <div class="form-group no-fields">
                  <p>
                    <strong>Order Date / Time:</strong>
                    {{ dataSet.orderDateTime | moment("ddd, MMM Do, YYYY h:mma") }}
                  </p>

                  <p>
                    <strong>Payment Type:</strong>
                    Credit Card (ending in {{ dataSet.creditCardNumber }})
                  </p>
                </div>
              </div>
            </div>


            <h2>
              Customer Information
              <label for="notifyCustomerByEmail" v-if="!dataRef">
                <input id="notifyCustomerByEmail" v-model="notifyCustomerByEmail" type="checkbox" />
                Notify Customer by Email
              </label>
            </h2>

            <!-- Customer Info Fields -->
            <div class="form-section">
              <div class="two-col-form">
                <div class="form-group">
                  <label for="customerFirstName">First Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="customerFirstName" v-model="dataSet.customerFirstName" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="customerLastName">Last Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="customerLastName" v-model="dataSet.customerLastName" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="emailAddress">Email Address<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="emailAddress" v-model="dataSet.emailAddress" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="phoneNumber">Phone Number<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="phoneNumber" v-model="dataSet.phoneNumber" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="address">Address<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="address" v-model="dataSet.address" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="city">City<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="city" v-model="dataSet.city" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group mb">
                  <label for="settingsCountry">Country<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="dataSet.country"
                      @input="resetRegion()"
                      :options="getCountryList"
                      label="name"
                      :reduce="country => country.name"
                      id="settingsCountry">
                    </v-select>
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group hidden-field" v-if="dataSet.country && regions && regions.length">
                  <label for="settingsRegion">Province / State<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select :reduce="region => region.name"
                      v-model="dataSet.stateProvince"
                      :options="selectedCountryRegions(dataSet.country)"
                      label="name"
                      value="name"
                      id="settingsRegion">
                    </v-select>
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="city">Postal / Zip Code<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="city" v-model="dataSet.zipPostal" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!-- Payment Fields -->
            <div class="form-section" v-if="!dataRef">
              <h2>
                Payment Information

                <label for="showPaymentForm">
                  <input id="showPaymentForm" v-model="showPaymentForm" type="checkbox" />
                  Require Payment for Order
                </label>
              </h2>

              <div class="two-col-form" v-if="showPaymentForm">
                <div class="form-group">
                  <label for="creditCardName">Cardholder Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="creditCardName" v-model="dataSet.creditCardName" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="creditCardNumber">Card Number<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="creditCardNumber" v-model="dataSet.creditCardNumber" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="creditCardExpiry">Expiry<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }" class="select-group">

                      <v-select
                        v-model="dataSet.creditCardExpiryMonth"
                        :options="getMonthList"
                        label="name"
                        :reduce="month => month.value"
                        placeholder="- Select Month -"
                        id="creditCardExpiry">
                      </v-select>

                      <v-select
                        :reduce="year => year.toString()"
                        v-model="dataSet.creditCardExpiryYear"
                        :options="getExpiryYearList"
                        placeholder="- Select Year -"
                        id="creditCardExpiry">
                      </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="creditCardCvv">CVV<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" class="input-half" id="creditCardCvv" v-model="dataSet.creditCardCvv" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <!-- Summary of Charges -->
        <div class="content-panel">
          <div class="panel-title">
            <h3>Summary of Charges</h3>
          </div>
          <div class="panel-body">
            <div class="charges-summary" v-if="show.id || dataSet.grossOrderAmount">
              <p v-if="dataRef"><strong>Order Status: {{dataSet.status}}</strong></p>

              <p>{{ dataSet.numberOfTickets }} Tickets x ${{ dataSet.ticketPrice ? dataSet.ticketPrice : show.ticketPrice }} Per Ticket</p>

              <div class="flex">
                <p>
                  <span>Subtotal:</span>
                  <span>${{beforeTaxes.toFixed(2)}}</span>
                </p>

                <p>
                  <span>Taxes:</span>
                  <span>${{taxes.toFixed(2)}}</span>
                </p>

                <p>
                  <span>Fees:</span>
                  <span>${{fees.toFixed(2)}}</span>
                </p>

                <p v-if="dataRef && dataSet.discountValue">
                  <span>Discount Amount:</span>
                  <span>${{dataSet.discountValue.toFixed(2)}}</span>
                </p>

                <p v-if="dataSet.discountType === 'coupon'">
                  <span>Coupon Code:</span>
                  <span>{{dataSet.couponCode}}</span>
                </p>

                <p class="total">
                  <span>Total:</span>
                  <span>${{totalCost.toFixed(2)}}</span>
                </p>

                <p class="total" v-if="dataRef && dataSet.totalOrderAmountDiscounted">
                  <span>Discounted Total:</span>
                  <span>${{dataSet.totalOrderAmountDiscounted}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Pricing & Discounting Fields -->
        <div class="content-panel order-discounting" v-if="!dataRef">
          <div class="panel-title">
            <h3>Payment &amp; Pricing</h3>
          </div>
          <div class="panel-body">
            <div v-if="dataSet.status !== 'Completed'">
              <div class="checkbox-group">
                <label for="paid">
                  <input type="checkbox" id="paid" v-model="dataSet.paid" @click="dataSet.paid = !dataSet.paid" />
                  Paid? <i class="fa fa-money green"></i>
                </label>
              </div>
              <div class="discount-group" v-if="dataSet.paid">
                <div class="form-group">
                  <label for="">Paid by:</label>
                  <select v-model="dataSet.paymentMethod">
                    <option :value="undefined" disabled>- Select -</option>
                    <option value="cash">Cash</option>
                    <option value="creditDebit">Credit/Debit</option>
                    <option value="moneyTransfer">Money Transfer</option>
                    <option value="cheque">Cheque</option>
                  </select>
                </div>
              </div>

              <div class="checkbox-group">
                <label for="overridePrice">
                  <input type="checkbox" id="overridePrice" v-model="dataSet.overridePrice" @click="dataSet.overridePrice = !dataSet.overridePrice" />
                  Discount the Price <i class="fa fa-tag green"></i>
                </label>
              </div>

              <div class="discount-group" v-if="dataSet.overridePrice">
                <div class="form-group" v-if="show">
                  <label for="">Discount Type:</label>
                  <select v-model="dataSet.discountType">
                    <option :value="undefined" disabled>- Select -</option>
                    <option value="percentageTotal">% off Total</option>
                    <option value="dollarTotal">$ off Total</option>
                    <option value="percentageTicket">% off per Ticket</option>
                    <option value="dollarTicket">$ off per Ticket</option>
                  </select>
                </div>
                <div class="form-group" v-if="show">
                  <label for="">Discount Value:</label>
                  <input type="number" v-model="dataSet.discountValue" name="Discount Value" />
                </div>

                <p class="total">
                  <strong>Discounted Total: <span v-if="totalCost">${{ discountedPriceTotal }} CAD</span> <span v-else>$0 CAD</span></strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Custom Fulfillment Fields -->
        <div class="content-panel" v-if="dataRef">
          <div class="panel-title">
            <h3>Customer Status</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <select v-model="dataSet.customerStatus">
                <option :value="undefined" disabled>- Select -</option>
                <option value="fulfilled">Fulfilled</option>
                <optgroup label="Partially Fulfilled">
                  <option v-for="n in (dataSet.numberOfTickets - 1)" :key="n">{{n}}</option>
                </optgroup>
                <option value="noShow">No Show</option>
              </select>
            </div>
            <button class="btn btn-sec btn-full" v-if="dataRef" @click="notifyCustomer(getCurrentPartner, dataRef)">
              <i class="fal fa-envelope"></i>
              Email Confirmation to Customer
            </button>
          </div>
        </div>

        <!-- Order Notes -->
        <div class="content-panel">
          <div class="panel-title">
            <h3>Order Notes</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="orderNotes">Add any notes specific to this order:</label>
              <textarea id="orderNotes" v-model="dataSet.orderNotes"></textarea>
            </div>
          </div>
        </div>

        <!-- Delete Order -->
        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Order
          </button>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import PaymentApi from "../../services/admin/paymentApi";
import EmailApi from "../../services/admin/emailApi";

export default {
  name: 'Order',
  data() {
    return {
      paymentApi: new PaymentApi(),
      emailApi: new EmailApi(),
      dataRef: this.$route.params.ref,
      dataSet: {
        id: null,
        address: '',
        city: '',
        country: '',
        creditCardExpiry: '',
        creditCardExpiryYear: '',
        creditCardExpiryMonth: '',
        creditCardName: '',
        creditCardNumber: '',
        customerFirstName: '',
        customerLastName: '',
        customerStatus: '',
        discountType: '',
        discountValue: 0,
        emailAddress: '',
        eventId: null,
        numberOfTickets: 2,
        totalOrderAmount: null,
        orderDateTime: null,
        orderNotes: '',
        overridePrice: false,
        paid: false,
        paymentMethod: '',
        phoneNumber: '',
        showId: null,
        stateProvince: '',
        status: '',
        ticketPrice: null,
        zipPostal: '',
        paymentResult: null,
        grossOrderAmount: null,
        serviceFee: null,
        taxes: null
      },
      regions: [],
      event: {},
      show: {
        id: null,
        ticketPrice: 0,
        taxRatePercentage: 0,
        applyServiceFeePerItem: false,
        serviceFee: 0
      },
      componentKey: 0,
      selectedShow: {},
      ticketsRemaining: null,
      eventList: [],
      showList: [],
      numberOfTickets: [1, 2, 3, 4, 5, 6],
      currentEventList: [],
      selectedEvent: {},
      newDate: new Date().toISOString(),
      loading: false,
      saving: false,
      showPaymentForm: true,
      notifyCustomerByEmail: true
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    ...mapGetters ({
      getCurrentPartner: 'getCurrentPartner'
    }),

    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Order'
      } else {
        return 'Add an Order'
      }
    },

    discountedPriceTotal: function() {
      let self = this;

      let discountType = self.dataSet.discountType;
      let discountValue = parseFloat(self.dataSet.discountValue);
      let numberOfTickets = self.dataSet.numberOfTickets;
      let taxes = self.show.taxRatePercentage;
      let beforeTaxes = self.beforeTaxes;
      let fees = self.fees;
      let totalCost = self.totalCost;

      let result = null;
      if (discountType === 'dollarTicket') {
        let discounted = beforeTaxes - (discountValue * numberOfTickets);
        result = discounted + ((taxes/100) * discounted) + fees;
      }
      else if (discountType === 'percentageTicket') {
        let discounted = beforeTaxes - (beforeTaxes * (discountValue / 100));
        result = discounted + ((taxes/100) * discounted) + fees;
      }
      else if (discountType === 'percentageTotal') {
        result = Math.round(((totalCost) - (totalCost * (discountValue / 100))) * 100) / 100;
      }
      else if (discountType === 'dollarTotal') {
        result = totalCost - discountValue;
      }

      return result;
    },

    beforeTaxes: function() {
      let self = this;

      if(self.dataRef) { return self.dataSet.grossOrderAmount }
      else {
          if(self.show.id) {
            return self.dataSet.numberOfTickets * self.show.ticketPrice;
          } else { return 0 }
      }
    },

    taxes: function() {
      let self = this;

      if(self.dataRef) { return self.dataSet.taxes ? self.dataSet.taxes : 0; }
      else {
        if(self.show.id) {
          return self.dataSet.numberOfTickets * self.show.ticketPrice * ((self.show.taxRatePercentage || 0) / 100);
        } else { return 0 }
      }
    },

    fees: function() {
      let self = this;
      if(self.dataRef) { return self.dataSet.serviceFee ? self.dataSet.serviceFee : 0; }
      else {
        if(self.show.id) {
          return self.show.applyServiceFeePerItem ? self.dataSet.numberOfTickets * (self.show.serviceFee || 0) : (self.show.serviceFee || 0);
        } else { return 0 }
      }
    },

    totalCost: function() {
      let self = this;
      let totalCost = 0;

      if (self.dataRef) {
        totalCost += self.dataSet.totalOrderAmount;
      } else {
        totalCost = (self.fees + self.taxes + self.beforeTaxes);
      }
      return totalCost;
    },

    ...mapGetters ([
      'db',
      'getCountryList',
      'getMonthList',
      'getExpiryYearList'
    ])
  },

  components: {

  },

  async mounted() {
    let self = this;

    if(self.dataRef) {
      await self.getOrder();
      await self.getEvent();
      self.selectedCountryRegions(self.dataSet.country);
    } else {
      self.dataSet.numberOfTickets = 2;
    }

    await self.getEventList();

  },

  methods: {
    notifyCustomer: async function (partnerId, orderId) {
      let self = this;
      await self.emailApi.sendTicketOrderEmail(partnerId, orderId);

      self.$notify({
        group: 'saved',
        title: 'Customer Successfully Notified',
        type: 'success'
      });
    },
    getOrder: async function() {
      let self = this;
      self.loading = true;

      let ordersData = await self.db.collection('orders').doc(self.dataRef).get();
      self.dataSet = ordersData.data();

      self.loading = false;
    },

    getEvent: async function() {
      let self = this;
      let ref = self.dataSet.eventKey ? self.dataSet.eventKey : self.dataSet.eventId;
      let eventData = await self.db.collection('events').doc(ref).get();

      self.event = eventData.data();
      self.show = self.event.shows.find(show => show.id === self.dataSet.showId);
    },

    getEventList: async function() {
      let self = this;
      let dataSet = await self.db.collection('events').get();
      dataSet = dataSet.docs.map(doc => doc.data());

      self.eventList = dataSet;

      self.currentEventList = self.eventList.filter(event => Vue.moment(event.endDate).isSameOrAfter(new Date(), "day"))
    },

    selectEvent: function(value) {
      let self = this;
      let event = self.eventList.find(event => event.id === value);
      self.selectedEvent = event;
      self.showList = event.shows;
    },

    selectShow: function(value) {
      let self = this;
      let show = self.showList.find(show => show.id === value);
      self.show = show;
      self.ticketsRemaining = show.totalTickets - (show.ticketsSold || 0);
    },

    saveChanges: async function() {
      let self = this;
      let success = await self.$refs.ordersForm.validate();
      let orderId = JSON.stringify(Date.now());
      let ref = self.dataRef ? self.dataRef : orderId;

      if(success) {
        self.saving = true;
        if(self.dataRef) {
          try {
            await self.db.collection('orders').doc(ref).update(self.dataSet);
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Order Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            let order = self.dataSet;
            order.id = ref;
            order.orderDateTime = self.newDate;
            order.customerStatus = order.customerStatus || '';
            order.discountType = order.discountType || '';
            order.discountValue = order.discountValue ? parseFloat(order.discountValue) : 0;
            order.ticketPrice = self.show.ticketPrice || 0;
            order.paymentMethod = self.showPaymentForm ? 'creditDebit' : '';
            order.serviceFee = self.fees || 0;
            order.taxes = self.taxes || 0;
            order.grossOrderAmount = self.beforeTaxes;
            order.totalOrderAmount = self.totalCost;
            order.totalOrderAmountDiscounted = self.discountedPriceTotal;
            order.orderNotes = order.orderNotes || '';

            let paymentResult = null;
            if (self.showPaymentForm) {
              order.creditCardExpiry = `${order.creditCardExpiryYear.slice(2)}${order.creditCardExpiryMonth}`;
              paymentResult = (await self.paymentApi.processPayment(
                  self.getCurrentPartner,
                  order.totalOrderAmountDiscounted || order.totalOrderAmount,
                  order.creditCardNumber,
                  order.creditCardExpiry,
                  `Customer: ${order.customerFirstName} ${order.customerLastName} - Event: ${order.eventId} - Show: ${order.showId} - Tickets: ${order.numberOfTickets}`
              )).data;
              if (!paymentResult.success) {
                throw `Error Processing Payment: ${paymentResult.message}`;
              }
            }

            delete order.creditCardExpiryMonth;
            delete order.creditCardExpiryYear;

            order.creditCardNumber = order.creditCardNumber.substr(order.creditCardNumber.length - 4);

            order.status = self.showPaymentForm ? paymentResult && paymentResult.success ? 'Completed' : 'Failed': 'Reserved';
            order.paid = self.showPaymentForm ? paymentResult.success : false;
            order.paymentResult = paymentResult;

            await self.db.collection('orders').doc(ref).set(order);

            if (self.notifyCustomerByEmail) {
              await self.emailApi.sendTicketOrderEmail(self.getCurrentPartner, order.id);
            }

            self.$notify({
              group: 'saved',
              title: 'Order Successfully Created',
              type: 'success'
            });

            self.saving = false;
            await self.$router.push({ path: '/orders/' + ref });
            await self.getOrder();
            await self.getEvent();
            self.selectedCountryRegions(self.dataSet.country);
            await self.getEventList();
          }
          catch (error) {
            self.saving = false;

            self.$notify({
              group: 'saved',
              title: 'Error Creating Order - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this order?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('orders').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Order Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Orders'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    selectedCountryRegions: function (name) {
      let self = this;

      var country = self.getCountryList.find((country) => {
        if(name) {
          if (country.name.toUpperCase() === name.toUpperCase()) {
            return country;
          }
        }
      });

      if (country && country.regions) {
        self.regions = country.regions;
        return country.regions;
      }
      if (country && country.regionNames) {
        self.regions = country.regionNames;
        return country.regionNames;
      }
      return null;
    },

    resetRegion: function() {
      let self = this;
      self.regions = [];
      self.dataSet.stateProvince = '';
      self.selectedCountryRegions(self.dataSet.country);
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Orders' });
    }
  }


}
</script>
