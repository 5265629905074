<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <button class="btn" @click="updateSettings()" title="Save Changes">Save Changes</button>
    </div>

    <p v-if="loading">Loading</p>

    <div v-else>
      <ValidationObserver ref="settingsForm">
        <div class="settings-form">
          <div class="content-panel">
            <div class="display-group">
              <div class="label-description">
                <h3>Logo Link</h3>
                <p>Choose where to send a user when they click on your logo.</p>
              </div>
              <div class="controls">
                <div class="form-group">
                  <input type="text" v-model="menus.logoLink.link" />
                </div>
              </div>
            </div>

            <div class="display-group">
              <div class="label-description">
                <h3>Header Navigation Menu</h3>
                <p>Create a menu to be displayed in the header of the Booking Platform.</p>
              </div>
              <div class="controls">
                <div class="menu-list">
                  <draggable
                    v-model="menus.headerMenu"
                    group="menuItems"
                    @start="drag=true"
                    @end="drag=false"
                    handle=".sortable-handle"
                    >
                    <div class="menu-item" v-for="(menu, headerMenuIndex) in menus.headerMenu" :key="menu.id">
                      <div class="sortable-handle">
                        <i class="fa fa-grip-vertical"></i>
                      </div>
                      <input type="text" class="title" v-model="menu.title" placeholder="Link Title" />
                      <input type="text" class="link" v-model="menu.link" placeholder="Link URL" />
                      <button class="btn-text" title="Delete Item" @click="deleteMenuItem('header', headerMenuIndex)">
                        <i class="fal fa-times-circle"></i>
                      </button>
                    </div>
                  </draggable>
                </div>
                <button @click="addMenuItem('header')" title="Add Menu Item" class="btn btn-sec">
                  <i class="fal fa-plus"></i>
                  Add Menu Item
                </button>
              </div>
            </div>

            <div class="display-group end">
              <div class="label-description">
                <h3>Footer Navigation Menu</h3>
                <p>Create a menu to be displayed in the footer of the Booking Platform.</p>
              </div>
              <div class="controls">
                <div class="menu-list">
                  <draggable
                  v-model="menus.footerMenu"
                  group="menuItems"
                  @start="drag=true"
                  @end="drag=false"
                  handle=".sortable-handle"
                  >
                  <div class="menu-item" v-for="(menu, footerMenuIndex) in menus.footerMenu" :key="menu.id">
                    <div class="sortable-handle">
                      <i class="fa fa-grip-vertical"></i>
                    </div>
                    <input type="text" class="title" v-model="menu.title" placeholder="Link Title" />
                    <input type="text" class="link" v-model="menu.link" placeholder="Link URL" />
                    <button class="btn-text" title="Delete Item" @click="deleteMenuItem('footer', footerMenuIndex)">
                      <i class="fal fa-times-circle"></i>
                    </button>
                  </div>
                  </draggable>
                </div>
                <button @click="addMenuItem('footer')" title="Add Menu Item" class="btn btn-sec">
                  <i class="fal fa-plus"></i>
                  Add Menu Item
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>

import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import PartnerApi from "@/services/partnerApi";

export default {
  name: 'WebsiteMenus',
  data() {
    return {
      title: 'Menu Settings',
      partnerApi: null,
      menus: {
        headerMenu: [
          {
            title: 'Support',
            link: 'support'
          }
        ],
        footerMenu: [
          {
            title: 'Terms',
            link: 'terms'
          },
          {
            title: 'Privacy',
            link: 'privacy'
          },
          {
            title: 'Support',
            link: 'support'
          }
        ],
        logoLink: {
          link: '/',
          title: 'Home'
        }
      },
      loading: false,
    }
  },
  metaInfo: {
    title: 'Menu Settings'
  },
  computed: {
    ...mapGetters([
        'db',
    ]),
  },

  components: {
    'draggable': draggable
  },

  async mounted() {
    let self = this;
    self.partnerApi = new PartnerApi();
    self.getSettings();
  },

  methods: {
    addMenuItem: function(menu) {
      let self = this;
      let menuItem = {
        title: '',
        link: ''
      }
      if(menu === 'header') {
        self.menus.headerMenu.push(menuItem);
      }

      if(menu === 'footer') {
        self.menus.footerMenu.push(menuItem);
      }

    },

    deleteMenuItem: function(menu, index) {
      let self = this;

      if(menu === 'header') {
        self.menus.headerMenu.splice(index, 1);
      }

      if(menu === 'footer') {
        self.menus.footerMenu.splice(index, 1);
      }

    },


    getSettings: async function() {
      let self = this;
      self.loading = true;

      let menus = await self.db.get();
      self.menus = menus.data().menus;

      self.loading = false;
    },

    updateSettings: async function(ref) {
      let self = this;
      let menus = self.menus;

      let success = await self.$refs.settingsForm.validate();
      if(success) {
        try {
          await self.db.update({ menus });
          // await self.partnerApi.clearSiteDataCache(self.getCurrentPartner);
          self.$notify({
            group: 'saved',
            title: 'Settings Updated',
            type: 'success'
          });
        }
        catch (error) {
          self.$notify({
            group: 'saved',
            title: 'Error Updating Settings',
            type: 'error'
          });
        }
      }
    }

  },



  watch: {
    'db': function() {
      let self = this;
      self.getSettings();
    }
  }
}
</script>
