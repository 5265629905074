var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Select a Product:")]),_c('v-select',{attrs:{"options":_vm.products,"label":"name","reduce":product => product.id,"placeholder":"- Select -"},model:{value:(_vm.productKey),callback:function ($$v) {_vm.productKey=$$v},expression:"productKey"}})],1),(_vm.productKey)?_c('div',{staticClass:"report-totals"},[_c('h4',[_vm._v("Report Totals:")]),_c('div',{staticClass:"items"},[_c('div',[_c('strong',[_vm._v("Total Orders: "+_vm._s(_vm.reportData.ordersCount))])]),_c('div',[_c('strong',[_vm._v("Failed Orders: "+_vm._s(_vm.reportData.failedOrdersCount))])]),_c('div',[_c('strong',[_vm._v("Total Products: "+_vm._s(_vm.reportData.productCount))])]),_c('div',[_c('strong',[_vm._v("Total Revenue: $"+_vm._s(_vm.reportData.revenue.toFixed(2)))])]),(_vm.reportData.ordersCount > 0 || _vm.reportData.failedOrdersCount > 0)?_c('div',{staticClass:"export"},[_c('button',{staticClass:"btn btn-sec",on:{"click":function($event){return _vm.downloadAsCsv()}}},[_c('i',{staticClass:"fa fa-file-export"}),_vm._v(" Export To CSV ")])]):_vm._e()])]):_vm._e(),(_vm.reportData.ordersCount > 0 || _vm.reportData.failedOrdersCount > 0)?_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Orders'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.tableSearchTerm=$event.target.value}}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.reportData.results,"styleClass":"vgt-table bookings-table","sort-options":{
          enabled: true,
          initialSortBy: {field: 'orderDateTime', type: 'asc'}
      },"search-options":{
          enabled: true,
          skipDiacritics: true,
          externalQuery: _vm.tableSearchTerm
      },"pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 50,
          position: 'bottom',
          dropdownAllowAll: false,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
      }},on:{"on-row-click":_vm.viewPage},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'id')?_c('span',[_c('a',{attrs:{"title":"View Order"}},[_c('strong',[_vm._v(_vm._s(props.row.id))])])]):(props.column.field == 'orderAmount')?_c('span',[_vm._v(" $"+_vm._s(props.row.orderAmount)+" ")]):_vm._e()]}}],null,false,3073610124)})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fa-thin fa-search"})])
}]

export { render, staticRenderFns }