<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
    </div>

    <div class="content-panel">
      <div class="panel-body no-title">

        <div class="form-group">
          <label for="">Select a Report Type:</label>
          <v-select
            v-model="selectedReport"
            :options="reportOptions"
            label="name"
            :reduce="report => report.value"
            placeholder="- Select -">
          </v-select>
        </div>

        <div>
          <div class="body-main" v-if="selectedReport == 'sales-summary-event'">
            <SalesSummaryByEvent :events="events" />
          </div>

          <div class="body-main" v-if="selectedReport == 'sales-summary-show'">
            <SalesSummaryByShow :events="events" />
          </div>

          <div class="body-main" v-if="selectedReport == 'sales-summary-date'">
            <SalesSummaryByDateRange :events="events" />
          </div>

          <div class="body-main" v-if="selectedReport == 'product-summary-product'">
            <ProductSummaryReport :products="products" />
          </div>

          <div class="body-main" v-if="selectedReport == 'product-summary-date'">
            <ProductSummaryByDateRange :products="products" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import SalesSummaryByEvent from '@/components/reports/SalesSummaryByEvent'
import SalesSummaryByShow from '@/components/reports/SalesSummaryByShow'
import SalesSummaryReportByDateRange from '@/components/reports/SalesSummaryByDateRange'
import ProductSummaryReport from '@/components/reports/ProductSummaryByProduct'
import ProductSummaryReportByDateRange from '@/components/reports/ProductSummaryByDateRange'

export default {
  name: 'Reports',
  data() {
    return {
      title: 'Reports',
      selectedReport: '',
      reportOptions: [
        {
          name: 'Ticket Sales by Event',
          value: 'sales-summary-event'
        },
        {
          name: 'Ticket Sales by Show',
          value: 'sales-summary-show'
        },
        {
          name: 'Ticket Sales by Date',
          value: 'sales-summary-date'
        },
        {
          name: 'Product Sales by Product',
          value: 'product-summary-product'
        },
        {
          name: 'Product Sales by Date',
          value: 'product-summary-date'
        }
      ],
      events: [],
      products: []
    }
  },
  components: {
    'SalesSummaryByEvent': SalesSummaryByEvent,
    'SalesSummaryByShow': SalesSummaryByShow,
    'SalesSummaryByDateRange': SalesSummaryReportByDateRange,
    'ProductSummaryReport': ProductSummaryReport,
    'ProductSummaryByDateRange': ProductSummaryReportByDateRange,
  },

  metaInfo: {
    title: 'Reports'
  },

  computed: {
    ...mapGetters([
        'db',
        'getCurrentPartner'
    ]),
  },

  async mounted () {
    let self = this;

    await self.getEvents();
    await self.getProducts();
  },

  watch: {
    getCurrentPartner: function () {
      let self = this;
      self.getEvents();
      self.getProducts();
    }
  },

  methods: {

    getEvents: async function() {
      let self = this;
      let events = await self.db.collection('events').get();

      self.events = events.docs.map(doc => doc.data());

      self.events = self.events.sort(function(a,b){
        return new Date(b.startDate) - new Date(a.startDate);
      });
    },

    getProducts: async function() {
      let self = this;
      let products = await self.db.collection('products').get();

      self.products = products.docs.map(doc => doc.data());
    },

  }
}
</script>
