<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fa-thin fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Requests'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              styleClass="vgt-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'dateTime', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'dateTime' && props.row.dateTime">
                    {{ props.row.dateTime | moment("ddd, MMM Do h:mma") }}
                  </span>
                  <span v-else>
                      {{props.formattedRow[props.column.field]}}
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SupportRequests',
  data() {
    return {
      title: 'Support Requests',
      tableSearchTerm: '',
      columns: [
        {
          label: 'Date',
          field: 'dateTime',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email Address',
          field: 'emailAddress',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Message',
          field: 'message',
          tdClass: 'title-col',
        }
      ]
    }
  },

  metaInfo: {
    title: 'Support Requests'
  },

  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getContactEntriesData'
    })
  },

  async mounted() {
    let self = this;
    self.getGridData();
  },

  methods: {
    getGridData: async function(forceUpdate) {
      let self = this;

      if(!self.gridList.length || forceUpdate) {
        await self.$store.dispatch('bindContactEntriesData');
      }
    },
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  }
}
</script>
