<template>
  <div id="app" class="global-container">
    <slideout-panel></slideout-panel>
    <notifications group="saved" position="center top" />
    <v-dialog />
    <global-nav v-if="getCurrentUser" />

    <div class="body-container">
        <profile-bar v-if="getCurrentUser" />
        <router-view />
    </div>

  </div>
</template>


<script>
import {auth} from "@/firebaseConfig";
import {mapGetters} from 'vuex';


export default {
  data() {
    return {
      displayColor: null
    }
  },
  metaInfo: {
    title: 'WatchComedy.live Admin Platform',
  },
  computed: {
    ...mapGetters([
      'getIsWCL',
      'getCurrentPartnerData',
      'getCurrentUser'
    ])
  },

  methods: {
    updateCss: function() {
      let self = this;
      let isWcl = self.getIsWCL;

      if(!isWcl && self.getCurrentPartnerData) {
        let color = self.getCurrentPartnerData.display.brandColor.hex;

        if(color) {
          let css = `
            .profile .selector .avatar { background-color: ${color} !important; }
          `
          let head = document.head || document.getElementsByTagName('head')[0];
          let style = document.createElement('style');
          head.appendChild(style);

          style.rel = 'text/css';
          if (style.styleSheet){
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }
        }
      }
    }
  },

  mounted() {
    let self = this;

    self.updateCss();
  },

  watch: {
    'getCurrentPartnerData': function() {
      let self = this;
      self.updateCss();
    }
  },
}
</script>
