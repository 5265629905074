<template>
  <div>

    <div class="form-group">
      <label for="selectedEvent">Select an Event:</label>
      <v-select
          id="selectedEvent"
          v-model="eventKey"
          :options="events"
          :reduce="event => event.id"
          :getOptionLabel="option => `${option.name} - ${moment(option.startDate).format('ddd MMM DD, YYYY')} - ${moment(option.endDate).format('ddd MMM DD, YYYY')}`"
          placeholder="- Select -">
        </v-select>
    </div>

    <div class="report-totals" v-if="eventKey">
      <h4>Report Totals:</h4>
      <div class="items">
        <div><strong>Total Orders: {{reportData.ordersCount}}</strong></div>
        <div><strong>Failed Orders: {{reportData.failedOrdersCount}}</strong></div>
        <div><strong>Total Tickets: {{reportData.ticketsCount}}</strong></div>
        <div><strong>Total Revenue: ${{reportData.revenue.toFixed(2)}}</strong></div>

        <div class="export" v-if="reportData.ordersCount > 0 || reportData.failedOrdersCount > 0">
          <button @click="downloadAsCsv()" class="btn btn-sec">
            <i class="fa fa-file-export"></i> Export To CSV
          </button>
        </div>
      </div>
    </div>

    <div class="data-table" v-if="reportData.ordersCount > 0 || reportData.failedOrdersCount > 0">
      <div class="data-table-search">
          <div class="actions">
            <span class="search-icon"><i class="fa-thin fa-search"></i></span>
            <input class="search-input" type="text" :placeholder="'Search Orders'" v-model="tableSearchTerm" />
          </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="reportData.results"
        @on-row-click="viewPage"
        styleClass="vgt-table bookings-table"
        :sort-options="{
            enabled: true,
            initialSortBy: {field: 'orderDateTime', type: 'asc'}
        }"

        :search-options="{
            enabled: true,
            skipDiacritics: true,
            externalQuery: tableSearchTerm
        }"

        :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 50,
            position: 'bottom',
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
        }"
    >

        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <a title="View Order"><strong>{{ props.row.id }}</strong></a>
            </span>

            <span v-else-if="props.column.field == 'orderAmount'">
              ${{ props.row.orderAmount }}
            </span>
        </template>

      </vue-good-table>
    </div>
  </div>

</template>


<script>
import Vue from 'vue'
import { eventMixin } from '@/mixins/eventMixin';
import { Parser } from 'json2csv';
import {mapGetters} from "vuex";

export default {
  name: 'SalesSummaryEvent',

  data(){
    return {
      title: "Sales Summary by Show",
      moment: Vue.moment,
      eventKey: '',
      reportData: {results: [], originalResults: [], ordersCount: 0, failedOrderCount: 0, ticketsCount: 0, revenue: 0},
      tableSearchTerm: '',
      columns: [
        {
          label: 'ID',
          field: 'id',
          tdClass: 'title-col',

        },
        {
          label: 'Order Date',
          field: 'orderDateTime'
        },
        {
          label: 'Order Status',
          field: 'status'
        },
        {
          label: 'First Name',
          field: 'customerFirstName'
        },
        {
          label: 'Last Name',
          field: 'customerLastName'
        },
        {
          label: 'Number of Tickets',
          field: 'numberOfTickets'
        },
        {
          label: 'Order Amount',
          field: 'orderAmount'
        },
        {
          label: 'Coupon Code',
          field: 'couponCode'
        }
      ]
    }
  },

  props: {
    events: Array
  },

  computed: {
    ...mapGetters([
      'db',
      'getCurrentPartner'
    ]),
  },

  mixins: [eventMixin],

  methods: {
    downloadAsCsv() {
      let parser = new Parser({fields: [{
          label: 'ID',
          value: 'id'
        },{
          label: 'Order Date Time',
          value: 'orderDateTime'
        },{
          label: 'Status',
          value: 'status'
        },{
          label: 'Tickets',
          value: 'numberOfTickets'
        },{
          label: 'Amount',
          value: 'totalOrderAmount'
        },{
          label: 'Price Override',
          value: 'overridePrice'
        },{
          label: 'Discount Value',
          value: 'discountValue'
        },{
          label: 'Discount Type',
          value: 'discountType'
        },{
          label: 'Customer Status',
          value: 'customerStatus'
        },{
          label: 'First Name',
          value: 'customerFirstName'
        },{
          label: 'Last Name',
          value: 'customerLastName'
        },{
          label: 'Address',
          value: 'address'
        },{
          label: 'City',
          value: 'city'
        },{
          label: 'State/Province',
          value: 'stateProvince'
        },{
          label: 'Zip/Postal',
          value: 'zipPostal'
        },{
          label: 'Email',
          value: 'emailAddress'
        },{
          label: 'Phone',
          value: 'phoneNumber'
        },{
          label: 'CC Name',
          value: 'creditCardName'
        },{
          label: 'CC Number',
          value: 'creditCardNumber'
        },{
          label: 'CC Expiry',
          value: 'creditCardExpiry'
        },{
          label: 'Notes',
          value: 'orderNotes'
        }]});
      let csv = parser.parse(this.reportData.originalResults);
      let downloadLink = document.createElement("a");
      let blob = new Blob(["\ufeff", csv]);
      let url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "report.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    viewPage(params) {
      let self = this;
      let id = params.row.id;
      self.$router.push({name:'EditOrder',params:{ref:id}})
    },
  },

  watch: {
    getCurrentPartner: function(){
      let self = this;
      self.eventKey = null;
    },
    currentEvent: async function (selectedShow) {
      let self = this;
      let reportData = self.reportData = {results: [], originalResults: [], ordersCount: 0, failedOrdersCount: 0, ticketsCount: 0, revenue: 0};

      if (!selectedShow) {
        return;
      }

      let currentEventItem = self.currentEvent;

      let orders = await self.db
          .collection('orders')
          .where('eventId', '==', self.eventKey)
          .get();

      let results = orders.docs.map(doc => doc.data());

      for(let orderItem of results) {
        let newOrder = {
          'id': orderItem.id ? orderItem.id : null,
          'orderDateTime': Vue.moment(orderItem.orderDateTime).format("YYYY/MM/DD, HH:mma"),
          'status': orderItem.status,
          'customerFirstName': orderItem.customerFirstName,
          'customerLastName': orderItem.customerLastName,
          'creditCardNumber': orderItem.creditCardNumber,
          'orderAmount': orderItem.totalOrderAmount ? orderItem.totalOrderAmount : orderItem.orderAmount,
          'numberOfTickets': orderItem.numberOfTickets,
          'couponCode': orderItem.couponCode,
          'orderNotes': orderItem.orderNotes
        };
        reportData.results.push(newOrder);
        orderItem['id'] = orderItem.id;
        orderItem['event'] = currentEventItem.name;
        reportData.originalResults.push(orderItem);
        if (newOrder.status === 'Failed') {
          reportData.failedOrdersCount++;
        }
        else {
          reportData.ordersCount++;
          reportData.ticketsCount += parseInt(orderItem.numberOfTickets);
          reportData.revenue += orderItem.totalOrderAmount ? orderItem.totalOrderAmount : orderItem.orderAmount;
        }
      }

      self.reportData = reportData;


    }
  },
}

</script>
