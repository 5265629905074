<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'AddPromotion' }" tag="button" class="btn" title="Add Promotion">
        <i class="fa-thin fa-plus-circle"></i>
        Add Promotion
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fa-thin fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Promotions'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              @on-row-click="viewPage"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'title', type: 'asc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <a :title="props.row.title" v-if="props.column.field == 'title'">
                    <strong>{{ props.row.title }}</strong>
                  </a>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Promotions',
  data() {
    return {
      title: 'All Promotions',
      tableSearchTerm: '',
      columns: [
        {
          label: 'Title',
          field: 'title',
          tdClass: 'title-col',
        },
        {
          label: 'Slug',
          field: 'id',
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getPromotionsData'
    })
  },

  metaInfo: {
    title: 'All Promotions'
  },

  async mounted() {
    let self = this;
    self.getGridData();
  },

  methods: {
    getGridData: async function(forceUpdate) {
      let self = this;

      if(!self.gridList.length || forceUpdate) {
        await self.$store.dispatch('bindPromotionsData');
      }
    },

    viewPage: function(params) {
      let self = this;
      let id = params.row.id;

      self.$router.push({name: 'EditPromotion', params:{ref:id}})
    }
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  }
}
</script>
