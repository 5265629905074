import {fs} from '@/firebaseConfig';

const state = {
  currentUser: null,
  db: fs,
  currentPartner: null,
  currentPartnerData: null,
  isWCL: false
}

const mutations = {
  updateCurrentUser(context, user) {
    state.currentUser = user;
    if (!user) {
      state.db = null;
    }
  },

  updateCurrentPartner(context, partner) {
    state.currentPartner = partner;
    if (partner) {
      if (partner === 'watchcomedy.live') {
        state.isWCL = true;
        state.db = fs;
      }
      else {
        state.db = fs.collection('partners').doc(partner);
        state.isWCL = false;
      }
    }
    else {
      state.db = null;
    }
  },

  updateCurrentPartnerData(context, data) {
    state.currentPartnerData = data;
  },
}

const getters = {
  getCurrentUser: state => state.currentUser,
  getCurrentPartner: state => state.currentPartner,
  getCurrentPartnerData: state => state.currentPartnerData,
  db: state => state.db,
  getIsWCL: state => state.isWCL
}

const actions = {

  setCurrentUser (context, user) {
    context.commit('updateCurrentUser', user);
  },
  setCurrentPartner (context, partner) {
    context.commit('updateCurrentPartner', partner);
  },
  setCurrentPartnerData (context, data) {
    context.commit('updateCurrentPartnerData', data);
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
