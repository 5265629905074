var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar sticky"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"content-panel"},[_c('div',{staticClass:"panel-body no-title"},[_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Emails'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.tableSearchTerm=$event.target.value}}})]),(_vm.gridList && _vm.gridList.length > 0)?_c('div',{staticClass:"export"},[_c('button',{staticClass:"btn btn-sec",on:{"click":function($event){return _vm.downloadAsCsv()}}},[_c('i',{staticClass:"fa fa-file-export"}),_vm._v(" Export To CSV ")])]):_vm._e()]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.gridList,"styleClass":"vgt-table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'dateTime', type: 'desc'}
            },"search-options":{
                enabled: true,
                skipDiacritics: true,
                externalQuery: _vm.tableSearchTerm
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'dateTime' && props.row.dateTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.dateTime,"ddd, MMM Do h:mma"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fa-thin fa-search"})])
}]

export { render, staticRenderFns }