<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.name">- {{ dataSet.name }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges(dataRef ? dataRef : dataSet.id)">Save Changes</button>

        <router-link :to="{ name: 'Comics' }" title="All Comics" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="dataForm">
          <div class="comic-form">

            <div class="form-section">
              <div class="form-group">
                <label for="comicName">Name<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="comicName" @input="setId($event.target.value)" v-model="dataSet.name" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group" v-show="!dataRef">
                <label for="dataRef">ID<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="dataRef" v-model="dataSet.id" :disabled="dataRef" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="comicBiography">Comic Biography</label>
                <wysiwyg-editor
                  id="comicBiography"
                  v-model="dataSet.biography"
                  class="html-editor"
                  :api-key="tinyApi"
                  toolbar="undo redo | code | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>

            <div class="form-section">
              <h3>Website &amp; Social Networks</h3>

              <div class="form-group">
                <label for="comicWebsite">Comic's Website</label>
                <input type="text" id="comicWebsite" v-model="dataSet.website" />
              </div>

              <div class="two-col-form">
                <div class="form-group">
                  <label for="youtube">YouTube Featured Video URL</label>
                  <input type="text" id="youtube" v-model="dataSet.youtubeLink" />
                </div>

                <div class="form-group">
                  <label for="youtube">YouTube Channel ID</label>
                  <input type="text" id="youtube" v-model="dataSet.youtubeChannelId" />
                </div>

                <div class="form-group">
                  <label for="twitter">Twitter</label>
                  <input type="text" id="twitter" v-model="dataSet.twitterLink" />
                </div>

                <div class="form-group">
                  <label for="instagram">Instagram</label>
                  <input type="text" id="instagram" v-model="dataSet.instagramLink" />
                </div>

                <div class="form-group">
                  <label for="facebook">Facebook</label>
                  <input type="text" id="facebook" v-model="dataSet.facebookLink" />
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Thumb Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="`${storageContext}/comics/${dataSet.id}/`" :oldImgUrl="dataSet.imageUrl"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>SEO Settings</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="featuredComic">
                <input type="checkbox" v-model="dataSet.featured" id="featuredComic" />
                Feature this Comic
              </label>

              <label for="indexComic" v-if="isWCL">
                <input type="checkbox" v-model="dataSet.index" id="indexComic" />
                Index this Comic Page
              </label>
            </div>

            <div class="form-group">
              <label for="seoTitle">Meta Title</label>
              <input type="text" id="seoTitle" v-model="dataSet.seoTitle" />
            </div>

            <div class="form-group">
              <label for="seoDescription">Meta Description</label>
              <textarea id="seoDescription" v-model="dataSet.seoDescription"></textarea>
            </div>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Related Comics</h3>
          </div>
          <div class="panel-body">
            <v-select
              v-model="dataSet.relatedComics"
              :options="comicList"
              placeholder="- Select -"
              multiple
              label="name"
              id="relatedComics"
              name="Related Comics">
            </v-select>
          </div>
        </div>

        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Comic
          </button>
        </div>


      </div>
    </div>


  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'

export default {
  name: 'Comic',
  data() {
    return {
      dataRef: this.$route.params.ref,
      dataSet: { },
      comicList: [],
      loading: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      return self.dataRef ? 'Edit Comic' : 'Add a Comic';
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      isWCL: 'getIsWCL',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader
  },

  async created() {
    let self = this;
    self.getData();
  },

  methods: {
    getData: async function() {
      let self = this;
      self.loading = true;

      let comicsData = await self.db.collection('comedians').get();
      self.comicList = comicsData.docs.map(doc => doc.data());
      if(self.dataRef) {
        self.dataSet = self.comicList.find(comic => comic.id === self.dataRef);
      }

      self.loading = false;
    },

    saveChanges: async function(ref) {
      let self = this;

      let success = await self.$refs.dataForm.validate();

      if(success) {
        if(self.dataRef) {
          try {
            await self.db.collection('comedians').doc(ref).update(self.dataSet);

            self.$notify({
              group: 'saved',
              title: 'Comic Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            await self.db.collection('comedians').doc(ref).set(self.dataSet)
            self.$notify({
              group: 'saved',
              title: 'Comic Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/comics/' + ref });
            await self.getData();
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Comic - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this comic?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('comedians').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Comic Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Comics'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getThumbImgUrl (url) {
      let self = this;
      self.dataSet.imageUrl = url
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.dataSet.headerImageUrl = url
    },

    setId: function(value) {
      let self = this;
      if(!self.dataRef) {
        let id = value.replace(/\s+/g, '-')
        id = id.replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase();
        self.$set(self.dataSet, 'id', id);
      }
    }

  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Comics' });

    }
  }
}
</script>
