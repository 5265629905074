import axios from "axios";
import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class PartnerApi {

  async getPartner(partnerId) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/getPartner`, {partnerId}, {headers});
  }

  async logOut() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post('/api/logout', null, {headers});
  }

  async clearPartnerDataCache(partnerId) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/partner/clearPartnerDataCache`, {partnerId}, {headers});
  }

  async clearCache(partnerId, requestType, itemToClear) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/partner/clearCache`, {partnerId, requestType, itemToClear}, {headers});
  }
}
