<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
      <button class="btn" @click="updateSettings()">Update Settings</button>
    </div>

    <p v-if="loading">Loading</p>

    <div v-if="!loading">
      <ValidationObserver ref="settingsForm">
        <ul class="form-tabs">
          <li
            v-for="(tab, tabIndex) in formTabs"
            :key="tabIndex"
            @click="currentTab = tab"
            :class="{ 'active' : currentTab === tab}"
            >
            <h2>{{tab}}</h2>
          </li>
        </ul>
        <div class="settings-form">

          <div class="content-panel" v-if="currentTab === 'Settings'">
            <div class="display-group">
              <div class="label-description">
                <h3>Logo</h3>
                <p>This will display in the header on all pages of the platform. (<strong>Optimal Dimensions:</strong> 300px w by 140px h)</p>
              </div>
              <div class="controls">
                <div class="form-group">
                  <p class="display-logo">
                    <file-uploader v-on:downloadURL="getLogoUrl" :parentContext="`${storageContext}/display/logo/`" :oldImgUrl="display.logoUrl"></file-uploader>
                  </p>
                </div>
              </div>
            </div>

            <div class="display-group">
              <div class="label-description">
                <h3>Favicon</h3>
                <p>This will display in the browser's tab.</p>
              </div>
              <div class="controls">
                <div class="form-group">
                  <p class="display-favicon">
                    <file-uploader v-on:downloadURL="getFaviconUrl" :parentContext="`${storageContext}/display/favicon/`" :oldImgUrl="display.faviconUrl"></file-uploader>
                  </p>
                </div>
              </div>
            </div>

            <div class="display-group">
              <div class="label-description">
                <h3>Primary Brand Color</h3>
                <p>Set the default brand color used throughout the website.</p>
              </div>
              <div class="controls">
                  <color-picker v-model="colors" />
              </div>
            </div>

            <div class="display-group">
              <div class="label-description">
                <h3>Theme Color</h3>
                <p>Select between a light or dark website theme.</p>
              </div>
              <div class="controls">
                <v-select
                    class="currency-selector"
                    v-model="display.themeColor"
                    label="text"
                    :reduce="option => option.value"
                    :options="themeColors"
                    :searchable="false"
                />
              </div>
            </div>

            <div class="display-group">
              <div class="label-description">
                <h3>Default Currency</h3>
                <p>Set the default currency to display within the booking platform.</p>
              </div>
              <div class="controls">
                <v-select
                      class="currency-selector"
                      v-model="display.currency"
                      :reduce="currencyCode => currencyCode.code"
                      :options="getCurrencyList"
                      label="name"
                      :searchable="false"
                  />
              </div>
            </div>
          </div>

          <div class="content-panel" v-if="currentTab === 'Elements'">
            <div class="display-group">
              <div class="label-description">
                <h3>Header</h3>
                <p>Select an alignment for the Website Header</p>
              </div>
              <div class="controls">
                <v-select
                    class="currency-selector"
                    v-model="display.headerAlignment"
                    label="text"
                    :reduce="option => option.value"
                    :options="headerAlignmentOptions"
                    :searchable="false"
                />
              </div>
            </div>
          </div>

          <div class="content-panel" v-if="currentTab === 'CSS'">
            <code-editor class="custom-css" v-model="display.customStyles" :highlight="highlighter" line-numbers></code-editor>
          </div>

          <div class="content-panel" v-if="currentTab === 'Scripts'">
            <code-editor class="custom-css" v-model="display.customScripts" :highlight="highlighter" line-numbers></code-editor>
          </div>
        </div>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FileUploader from '@/components/FileUploader'
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/themes/prism-tomorrow.css';
import PartnerApi from "@/services/partnerApi";
import { Chrome } from 'vue-color'

export default {
  name: 'SettingsDisplay',
  data() {
    return {
      title: 'Display Settings',
      partnerApi: null,
      display: {},
      loading: false,
      currentTab: 'Settings',
      colors: '',
      formTabs: [
        'Settings',
        'Elements',
        'CSS',
        'Scripts'
      ],
      themeColors: [
        {
          text: 'Light Theme',
          value: 'light'
        },
        {
          text: 'Dark Theme',
          value: 'dark'
        }
      ],
      headerAlignmentOptions: [
        {
          text: 'Left',
          value: 'left'
        },
        {
          text: 'Center',
          value: 'center'
        }
      ],
    }
  },
  metaInfo: {
    title: 'Display Settings'
  },

  computed: {
    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ([
      'getCurrentPartner',
      'getCountryList',
      'getCurrencyList',
      'getLanguageList',
      'db'
    ])
  },

  components: {
    'FileUploader': FileUploader,
    'code-editor': PrismEditor,
    'color-picker': Chrome
  },

  async mounted() {
    let self = this;
    self.partnerApi = new PartnerApi();
    self.getSettings();
  },

  methods: {
    getSettings: async function() {
      let self = this;
      self.loading = true;
      let display = await self.db.get();
      self.display = display.data().display;
      self.colors = self.display.brandColor || '#4DBDC7';
      self.loading = false;
    },

    updateSettings: async function(ref) {
      let self = this;

      let display = self.display;
      let success = await self.$refs.settingsForm.validate();
      if(success) {
        self.display.brandColor = self.colors;
        try {
          await self.db.update({ display });
          self.$notify({
            group: 'saved',
            title: 'Settings Updated',
            type: 'success'
          });
        }
        catch(error) {
          self.$notify({
            group: 'saved',
            title: 'Error Updating Settings',
            type: 'error'
          });
        }
      }
    },

    getLogoUrl (url) {
      let self = this;
      self.display.logoUrl = url
    },

    getFaviconUrl (url) {
      let self = this;
      self.display.faviconUrl = url
    },

     highlighter: function(code) {
      return highlight(code, languages.js);
    },
  },



  watch: {
    'db': function() {
      let self = this;
      self.getSettings();
    }
  },
}
</script>
