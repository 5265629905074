var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar sticky"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'AddVenue' },"tag":"button","title":"Add Venue"}},[_c('i',{staticClass:"fa-thin fa-plus-circle"}),_vm._v(" Add Venue ")])],1),_c('div',{staticClass:"content-panel"},[_c('div',{staticClass:"panel-body no-title"},[_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Venues'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.tableSearchTerm=$event.target.value}}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.gridList,"styleClass":"vgt-table bookings-table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'featured', type: 'desc'}
            },"search-options":{
                enabled: true,
                skipDiacritics: true,
                externalQuery: _vm.tableSearchTerm
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }},on:{"on-row-click":_vm.viewPage},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('a',{attrs:{"title":"View Venue"}},[_c('strong',[_vm._v(_vm._s(props.row.name))])])]):(props.column.field == 'featured')?_c('span',[(props.row.featured)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fa-thin fa-search"})])
}]

export { render, staticRenderFns }