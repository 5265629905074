const axios = require('axios');

import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export default class DashboardApi {
  async ticketSalesAndRevenueByDays(numberOfDays) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/dashboard/ticketSalesAndRevenue`, {days: numberOfDays, timezone},{headers});
  }
  async upcomingEventsSalesSummary() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/dashboard/upcomingEventsSalesSummary`, {timezone}, {headers});
  }
}
