<template>
<div></div>
</template>

<script>

import PartnerApi from "@/services/partnerApi";
import {auth} from "@/firebaseConfig";

export default {
  name: 'Logout',
  data() {
    return {
      title: 'Logout',
      api: new PartnerApi()
    }
  },
  async created() {
    let self = this;
    await auth.signOut();
    await self.$store.dispatch('unbindAllData');
    await self.$store.dispatch('setCurrentUser', null);
    await self.$router.push('/login');
    await self.api.logOut();
  }
}
</script>
