const axios = require('axios');

import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class PurgeApi {

  async purgeUrl(partnerId, url) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/purge`, {partnerId, url}, {headers});
  }
}
