<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fa-thin fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Emails'" v-model="tableSearchTerm" />
              </div>

              <div class="export" v-if="gridList && gridList.length > 0">
                <button @click="downloadAsCsv()" class="btn btn-sec">
                  <i class="fa fa-file-export"></i> Export To CSV
                </button>
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              styleClass="vgt-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'dateTime', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'dateTime' && props.row.dateTime">
                  {{ props.row.dateTime | moment("ddd, MMM Do h:mma") }}
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { Parser } from 'json2csv';

export default {
  name: 'InsiderEmails',
  data() {
    return {
      title: 'All Insider Emails',
      tableSearchTerm: '',
      columns: [
        {
          label: 'Date',
          field: 'dateTime',
          tdClass: 'title-col'
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email Address',
          field: 'emailAddress',
        },
        {
          label: 'Birthday',
          field: 'birthday',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getEmailSignupsData'
    })
  },

  metaInfo: {
    title: 'All Insider Emails'
  },

  async mounted() {
    let self = this;
    self.getGridData();
  },

  methods: {
    getGridData: async function(forceUpdate) {
      let self = this;

      if(!self.gridList.length || forceUpdate) {
        await self.$store.dispatch('bindEmailSignupsData');
      }
    },

    downloadAsCsv() {
      let self = this;
      let parser = new Parser({fields: [{
          label: 'Submission Date',
          value: 'dateTime'
        },{
          label: 'Customer Name',
          value: 'name'
        },{
          label: 'Email Address',
          value: 'emailAddress'
        },{
          label: 'Birthday',
          value: 'birthday'
        }]});
      var csv = parser.parse(self.gridList);
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", csv]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "report.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  }
}
</script>
