<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'AddCoupon' }" tag="button" class="btn" title="Add Coupon">
        <i class="fa-thin fa-plus-circle"></i>
        Add Coupon
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fa-thin fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Coupons'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              @on-row-click="viewPage"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'startDate', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <a v-if="props.column.field == 'code' && props.row.code" :title="props.row.code">
                    {{ props.row.code }}
                  </a>
                  <div v-if="props.column.field == 'description'" v-html="props.row.description"></div>
                  <span v-if="props.column.field == 'startDate' && props.row.startDate">
                    {{ props.row.startDate | moment("ddd, MMM Do, YYYY") }}
                  </span>
                  <span v-if="props.column.field == 'endDate' && props.row.endDate">
                    {{ props.row.endDate | moment("ddd, MMM Do, YYYY") }}
                  </span>
                  
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Coupons',
  data() {
    return {
      title: 'All Coupons',
      tableSearchTerm: '',
      columns: [
        {
          label: 'Code',
          field: 'code'
        },
        {
          label: 'Description',
          field: 'description',
          tdClass: 'title-col',
        },
        {
          label: 'Start Date',
          field: 'startDate'
        },
        {
          label: 'End Date',
          field: 'endDate'
        },
        
      ]
    }
  },

  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getCouponsData'
    })
  },

  metaInfo: {
    title: 'All Coupons'
  },

  async mounted() {
    let self = this;
    self.getGridData();
  },

  methods: {
    getGridData: async function(forceUpdate) {
      let self = this;

      if(!self.gridList.length || forceUpdate) {
        await self.$store.dispatch('bindCouponsData');
      }
    },

    viewPage: function(params) {
      let self = this;
      let id = params.row.id;

      self.$router.push({name: 'EditCoupon', params:{ref:id}})
    }
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  },
}
</script>
