<template>
  <section class="login-page">
    <ValidationObserver ref="loginForm">
      <form @submit.prevent="login">
        <div class="login-form">
          <div class="login-header">
            <img src='../assets/img/logo.png' alt="TotalTravel" class="logo" />
          </div>

          <div class="login-body">
            <h3>Sign In to Manage Your Account</h3>

            <div class="form-group">
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" placeholder="Email" v-model="email" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="password" placeholder="Password" v-model="password" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-error" v-if="showError">Email or Password Incorrect.</div>

            <button type="submit" class="btn btn-primary">Login</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import {auth} from "@/firebaseConfig";

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      showError: false,
      loading: false
    }
  },

  metaInfo: {
    title: 'Login'
  },

  created () {
    let self = this;
    if(self.getCurrentUser) {
      self.$router.push({ name: 'Dashboard' })
    }
  },

  methods: {
    login: function() {
      let self = this;
      self.$refs.loginForm.validate().then(success => {
        if(success) {
          auth.signInWithEmailAndPassword(self.email, self.password)
          .then(function () {
            auth.currentUser.getIdTokenResult()
              .then(function(tokenResult) {
                if (tokenResult.claims && (tokenResult.claims.role === 'su' || tokenResult.claims.role === 'admin' || tokenResult.claims.role === 'agent')) {
                  self.$router.push('/');
                  self.$store.dispatch('unbindAllData');
                }
                else {
                  self.$store.dispatch('setCurrentUser', null);
                  auth.signOut().then(function () {
                      self.showError = true;
                      self.$set(self, 'showError', true);
                    })
                    .catch(err => {
                      console.error(err);
                    });
                }
            })
            .catch(err => {
              console.error(err);
            });
          })
          .catch(err => {
            self.showError = true;
          });
        }
      });
    }
  },

  computed: {
    ...mapGetters([
        'getCurrentUser'
    ])
  }
}
</script>
