<template>
  <div>
    <div class="two-col-form">
      <div class="form-group">
        <label for="fromDate">From Date:</label>
        <datetime id="fromDate" type="date" value-zone="local" zone="local" v-model="range.start" :auto="true" name="From Date" placeholder="mm-dd-yyyy"></datetime>
      </div>
      <div class="form-group">
        <label for="toDate">To Date:</label>
        <datetime id="toDate" type="date" value-zone="local" zone="local" v-model="range.end" :auto="true" name="To Date" :min-datetime="range.start" placeholder="mm-dd-yyyy"></datetime>
      </div>
    </div>

    <div class="report-totals" v-if="range.start && range.end">
      <h4>Report Totals:</h4>
      <div class="items">
        <div><strong>Total Orders: {{reportData.ordersCount}}</strong></div>
        <div><strong>Failed Orders: {{reportData.failedOrdersCount}}</strong></div>
        <div><strong>Total Products: {{reportData.productCount}}</strong></div>
        <div><strong>Total Revenue: ${{reportData.revenue.toFixed(2)}}</strong></div>

        <div class="export" v-if="reportData.ordersCount > 0 || reportData.failedOrdersCount > 0">
          <button @click="downloadAsCsv()" class="btn btn-sec">
            <i class="fa fa-file-export"></i> Export To CSV
          </button>
        </div>
      </div>
    </div>

    <div class="data-table" v-if="reportData.ordersCount > 0 || reportData.failedOrdersCount > 0">
      <div class="data-table-search">
          <div class="actions">
            <span class="search-icon"><i class="fa-thin fa-search"></i></span>
            <input class="search-input" type="text" :placeholder="'Search Orders'" v-model="tableSearchTerm" />
          </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="reportData.results"
        styleClass="vgt-table bookings-table"
        @on-row-click="viewPage"
        :sort-options="{
            enabled: true,
            initialSortBy: {field: 'orderDateTime', type: 'asc'}
        }"

        :search-options="{
            enabled: true,
            skipDiacritics: true,
            externalQuery: tableSearchTerm
        }"

        :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 50,
            position: 'bottom',
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
        }"
    >

        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <a title="View Order"><strong>{{ props.row.id }}</strong></a>
            </span>

            <span v-else-if="props.column.field == 'orderAmount'">
              ${{ props.row.orderAmount }}
            </span>
        </template>

      </vue-good-table>
    </div>
  </div>
</template>


<script>
import Vue from 'vue'
import { eventMixin } from '@/mixins/eventMixin';
import { Parser } from 'json2csv';
import { Datetime } from 'vue-datetime'
import {mapGetters} from "vuex";


export default {
  name: 'ProductSalesSummaryDate',

  data(){
    return {
      title: "Product Sales Summary by Date Range",
      productKey: '',
      reportData: {results: [], originalResults: [], ordersCount: 0, failedOrderCount: 0, ticketsCount: 0, revenue: 0},
      tableSearchTerm: '',
      columns: [
        {
          label: 'ID',
          field: 'id',
          tdClass: 'title-col',

        },
        {
          label: 'Order Date',
          field: 'orderDateTime'
        },
        {
          label: 'Order Status',
          field: 'status'
        },
        {
          label: 'First Name',
          field: 'customerFirstName'
        },
        {
          label: 'Last Name',
          field: 'customerLastName'
        },
        {
          label: 'Number of Products',
          field: 'productCount'
        },
        {
          label: 'Order Amount',
          field: 'totalOrderAmount'
        }
      ],
      range: {
        start: null,
        end: null
      }
    }
  },

    components: {
      Datetime: Datetime
    },

  props: {
    products: Array
  },

  mixins: [eventMixin],

  methods: {
    downloadAsCsv() {
      let parser = new Parser({fields: [{
          label: 'ID',
          value: 'id'
        },{
          label: 'Order Date Time',
          value: 'orderDateTime'
        },{
          label: 'Status',
          value: 'status'
        },{
          label: 'Count',
          value: 'productCount'
        },{
          label: 'Gross Amount',
          value: 'grossOrderAmount'
        },{
          label: 'Taxes',
          value: 'taxes'
        },{
          label: 'Service Fee',
          value: 'serviceFee'
        },{
          label: 'Total Amount',
          value: 'totalOrderAmount'
        },{
          label: 'Customer Status',
          value: 'customerStatus'
        },{
          label: 'First Name',
          value: 'customerFirstName'
        },{
          label: 'Last Name',
          value: 'customerLastName'
        },{
          label: 'Address',
          value: 'address'
        },{
          label: 'City',
          value: 'city'
        },{
          label: 'State/Province',
          value: 'stateProvince'
        },{
          label: 'Zip/Postal',
          value: 'zipPostal'
        },{
          label: 'Email',
          value: 'emailAddress'
        },{
          label: 'Phone',
          value: 'phoneNumber'
        },{
          label: 'CC Type',
          value: 'cardType'
        },{
          label: 'CC Name',
          value: 'creditCardName'
        },{
          label: 'CC Number',
          value: 'creditCardNumber'
        },{
          label: 'CC Expiry',
          value: 'creditCardExpiry'
        },{
          label: 'Feedback',
          value: 'orderFeedback'
        },{
          label: 'Notes',
          value: 'orderNotes'
        }]});
      var csv = parser.parse(this.reportData.originalResults);
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", csv]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "report.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    viewPage(params) {
      let self = this;
      let id = params.row.id;
      self.$router.push({name:'EditProductOrder',params:{ref:id}})
    },
  },

  computed: {
    ...mapGetters([
      'db',
      'getCurrentPartner'
    ]),
  },

  watch: {
    getCurrentPartner: function () {
      let self = this;
      self.range = {start: null, end: null};
      self.reportData = {results: [], originalResults: [], ordersCount: 0, failedOrdersCount: 0, productCount: 0, revenue: 0};
    },
    range: {
      async handler(range) {
        let self = this;
        let reportData = {results: [], originalResults: [], ordersCount: 0, failedOrdersCount: 0, productCount: 0, revenue: 0};

        if (!range.start || !range.end) {
          return;
        }

        let startDate = range.start.substring(0, 10) + 'T00:00:00.000Z';
        let endDate = range.end.substring(0, 10) + 'T23:59:59.999Z';

        let results = await self.db.collection('productOrders')
            .where('orderDateTime', '>=', startDate)
            .where('orderDateTime', '<=', endDate)
            .get();

        results = results.docs.map(item => item.data());

        for(let order of results) {
          reportData.results.push(
            {
              id: order.id,
              orderDateTime: Vue.moment(order.orderDateTime.replace('Z', '')).format("YYYY/MM/DD, HH:mma"),
              status: order.status,
              customerFirstName: order.customerFirstName,
              customerLastName: order.customerLastName,
              productCount: order.productCount,
              totalOrderAmount: order.totalOrderAmount ? order.totalOrderAmount.toFixed(2) : 0,
              grossOrderAmount: order.grossOrderAmount ? order.grossOrderAmount.toFixed(2) : 0,
              orderFeedback: order.orderFeedback,
              orderNotes: order.orderNotes,
              creditCardNumber: order.creditCardNumber,
              cardType: order.cardType,
              taxes: order.taxes ? order.taxes.toFixed(2) : 0,
              serviceFee: order.serviceFee ? order.serviceFee.toFixed(2) : 0
            }
          );
          reportData.originalResults.push(order);
          reportData.ordersCount++;
          if (order.status === 'Failed') {
            reportData.failedOrdersCount++;
          }
          else {
            reportData.revenue += parseFloat(order.totalOrderAmount);
            reportData.productCount += parseInt(order.productCount);
          }
        }

        self.reportData = reportData;
      },
      deep: true
    }
  },
}

</script>
