const state = {
    showFullNav: true,
    tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea',
}

const mutations = {
    toggleMainNav(context, showHide) {
        state.showFullNav = showHide;
    }
}

const actions = {
    toggleMainNav (context, showHide) {
        context.commit('toggleMainNav', showHide);
    },
}

const getters = {
    getShowFullNav: state => state.showFullNav,
    getTinyApi: state => state.tinyApi,
}

export default {
  state,
  mutations,
  getters,
  actions
}