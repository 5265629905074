<script>
import { Line, mixins } from 'vue-chartjs'
const {reactiveProps} = mixins;
export default {
  extends: Line,
  mixins: reactiveProps,
  props: ['chartData', 'options', 'styles'],
  mounted () {
    let self = this;
    self.updateChart();
  },
  methods: {
    updateChart() {
      let self = this;
      self.renderChart(self.chartData, self.options, self.styles);
    }
  },
  watch: {
    'chartData': function () {
      let self = this;
      self.updateChart();
    }
  }
}
</script>
