<template>
  <div class="file-upload">
    <button class="btn btn-sec btn-md" @click="selectFile" v-if="!uploadEnd && !uploading"><i class="fa-thin fa-images"></i> Upload an Image</button>
    <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
    
    <div v-if="uploading && !uploadEnd"><i class="fa-thin fa-spinner-third fa-spin"></i></div>
      
    <img v-if="uploadEnd" :src="downloadURL" />
    <div v-if="uploadEnd">
      <button class="btn btn-sm btn-sec" @click="deleteImage()"><i class="fa-thin fa-times"></i> Remove Image</button>
    </div>
  </div>
</template>

<script>
  import { fireStorage } from '@/firebaseConfig'

  export default {
    props: ['oldImgUrl', 'parentContext'],
    data () {
      return {
        progressUpload: 0,
        fileName: '',
        uploadTask: '',
        uploading: false,
        uploadEnd: false,
        downloadURL: ''
      }
    },
    created () {
      let self = this;

      if (self.oldImgUrl) self.setCoverImgOnUpdate()
    },
    methods: {
      selectFile () {
        let self = this;

        self.$refs.uploadInput.click()
      },
      detectFiles (e) {
        let self = this;

        let fileList = e.target.files || e.dataTransfer.files
        Array.from(Array(fileList.length).keys()).map(x => {
          self.upload(fileList[x])
        })
      },
      upload (file) {
        let self = this;

        self.fileName = file.name;
        self.uploading = true;
        self.uploadTask = fireStorage.ref(self.parentContext + '/' + file.name).put(file);
      },
      deleteImage () {
        let self = this;

        if (self.oldImgUrl === '') {
          self.deleteImgOnFirebase()
        } else {
          self.deleteImgOnUpdate()
        }
      },
      setCoverImgOnUpdate () {
        let self = this;

        self.uploadEnd = true
        self.downloadURL = self.oldImgUrl
      },
      deleteImgOnFirebase () {
        let self = this;

        fireStorage
          .ref('images/' + self.fileName)
          .delete()
          .then(() => {
            self.uploading = false
            self.uploadEnd = false
            self.downloadURL = ''
          })
          .catch((error) => {
            console.error(`file delete error occured: ${error}`)
          })
      },
      deleteImgOnUpdate () {
        let self = this;

        self.uploading = false
        self.uploadEnd = false
        self.downloadURL = ''
        self.$emit('downloadURL', self.downloadURL)
      }
    },
    watch: {
      uploadTask: function () {
        let self = this;
        self.uploadTask.on('state_changed', sp => {
            self.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
          },
          null,
          () => {
            self.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              self.uploadEnd = true;
              self.downloadURL = downloadURL
              self.$emit('downloadURL', downloadURL)
            })
          })
      }
    }
  }
</script>

<style>
  .progress-bar {
    margin: 10px 0;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0,0,0,0);
  }
</style>
