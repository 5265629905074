var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar sticky"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'AddOrder' },"tag":"button","title":"Add Order"}},[_c('i',{staticClass:"fa-thin fa-plus-circle"}),_vm._v(" Add Order ")])],1),_c('div',{staticClass:"content-panel"},[_c('div',{staticClass:"panel-body no-title"},[_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Orders'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.tableSearchTerm=$event.target.value}}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.gridList,"styleClass":"vgt-table bookings-table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'orderDateTime', type: 'desc'}
            },"search-options":{
                enabled: true,
                skipDiacritics: true,
                externalQuery: _vm.tableSearchTerm
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }},on:{"on-row-click":_vm.viewPage},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'orderDateTime')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.orderDateTime,"ddd, MMM Do h:mma"))+" ")]):(props.column.field === 'totalOrderAmount')?_c('span',[_vm._v(" $"+_vm._s(props.row.totalOrderAmount)+" ")]):(props.column.field === 'eventId')?_c('span',[_vm._v(" "+_vm._s(_vm.getEventName(props.row.eventId))+" ")]):(props.column.field === 'showId')?_c('span',[_vm._v(" "+_vm._s(_vm.getShowDate(props.row.eventId, props.row.showId))+" ")]):(props.column.field === 'orderNotes' && props.row.orderNotes)?_c('span',[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(props.row.orderNotes),expression:"props.row.orderNotes"}],staticClass:"fal fa-info-circle"})]):_vm._e()]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fa-thin fa-search"})])
}]

export { render, staticRenderFns }