export const eventMixin =  {
  methods: {
    selectedCountry: function (country) {
      let self = this;
      let result = self.getCountryList.find(function (item) {
        if (item.countryName == country)
        {
          return item;
        }
      });
      return result;
    }
  },
  computed: {
    currentProductOrder: function () {
      let self = this;
      let key = self.productOrderKey;
      return self.orders.find(function(item) { if (item.id == key) {return item;} });
    },

    currentProduct: function () {
      let self = this;
      let key = self.productKey;
      return self.products.find(function(item) { if (item.id == key) {return item;} });
    },

    currentOrder: function () {
      let self = this;
      let key = self.orderKey;
      let result = self.orders[key];
      self.eventKey = result.eventKey;
      self.countryName = result.country;

      // these orders are from previous customer side purchaseTicket
      if (!result.hasOwnProperty('customerStatus')) {
        result.isOldOrder = true;
        result.discountType = '';
        result.discountValue = 0;
        result.overridePrice = false;
        result.paid = result.status === 'Completed';
        result.paymentMethod = 'creditDebit';
        result.customerStatus = '';
      }

      return result;
    },

    currentEvent: function () {
      let self = this;
      let key = self.eventKey;
      let result = self.events.find(function (item) {
        if (item.id == key)
        {
          return item;
        }
      });
      return result;
    },

    currentShow: function () {
      let self = this;
      let key = self.showKey;
      let currentEvent = this.currentEvent;
      if (currentEvent) {
        let result = self.currentEvent.shows.find(function (item) {
          if (item.id == key)
          {
            return item;
          }
        });
        return result;
      }
    },
  }
}
