var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar sticky"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'AddUser' },"tag":"button","title":"Add a User"}},[_c('i',{staticClass:"fal fa-plus-circle"}),_vm._v(" Add a User ")])],1),_c('div',{staticClass:"content-panel"},[_c('div',{staticClass:"panel-body no-title"},[_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Users'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.tableSearchTerm=$event.target.value}}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.userList,"styleClass":"vgt-table bookings-table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'displayName', type: 'asc'}
            },"search-options":{
                enabled: true,
                skipDiacritics: true,
                externalQuery: _vm.tableSearchTerm
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }},on:{"on-row-click":_vm.viewPage},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'displayName')?_c('a',{attrs:{"title":"View"},on:{"click":function($event){return _vm.viewUser(props.row.uid)}}},[_c('strong',[_vm._v(_vm._s(props.row.displayName))])]):_vm._e()]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fal fa-search"})])
}]

export { render, staticRenderFns }