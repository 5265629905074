<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
      <button class="btn" @click="updateVenueInfo()" title="Save Changes">Save Changes</button>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">
        <ValidationObserver ref="settingsForm">
          <div class="settings-form">

            <div class="content-panel">
              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsVenueName">Venue Name<span class="req">*</span></label></h3>
                  <p>The display name for your Venue.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsVenueName" v-model="venueInfo.venueName" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsEmailAddress">Email Address<span class="req">*</span></label></h3>
                  <p>The email address that customer's will receive all emails from.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsEmailAddress" v-model="venueInfo.emailAddress" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsEmailAddressBcc">Bcc Email Address</label></h3>
                  <p>Optional email address that will be copied on all customer transactional and support emails.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <input type="text" id="settingsEmailAddressBcc" v-model="venueInfo.emailAddressBcc" />
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsPhoneNumber">Phone Number</label></h3>
                  <p>The Venue phone number.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <input type="text" id="settingsPhoneNumber" v-model="venueInfo.phoneNumber" />
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAddress">Address</label></h3>
                  <p>The physical address of the Venue.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsAddress" v-model="venueInfo.address" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAddress2">Address 2</label></h3>
                  <p>Suite or Unit Number</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <input type="text" id="settingsAddress2" v-model="venueInfo.address2" />
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsCity">City</label></h3>
                  <p>The city the venue is located in.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsCity" v-model="venueInfo.city" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Country</label></h3>
                  <p>The country the venue is located in.</p>
                </div>
                <div class="controls">
                  <div class="form-group mb">
                    <label for="settingsCountry">Country<span class="req">*</span></label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-select
                        v-model="venueInfo.country"
                        @input="resetRegion()"
                        :options="getCountryList"
                        label="name"
                        :reduce="country => country.code"
                        id="settingsCountry"
                        name="Country">
                      </v-select>
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group hidden-field" v-if="venueInfo.country && regions && regions.length">
                    <label for="settingsRegion">Region<span class="req">*</span></label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-select :reduce="region => (region.code ? region.code : region.name)"
                        v-model="venueInfo.region"
                        :options="selectedCountryRegions(venueInfo.country)"
                        label="name"
                        value="name"
                        id="settingsRegion"
                        name="Province/State">
                      </v-select>
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsPostalZipCode">Postal/Zip Code</label></h3>
                  <p>The postal or zip code for the agency.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsPostalZipCode" v-model="venueInfo.postalZipCode" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsSocialLinks">Social Links</label></h3>
                  <p>Setup any available links to Social Media pages.</p>
                </div>
                <div class="controls">
                  <div class="form-group mb">
                      <input type="text" placeholder="Twitter" v-model="venueInfo.socialLinks.twitter" />
                  </div>
                  <div class="form-group mb">
                      <input type="text" placeholder="YouTube" v-model="venueInfo.socialLinks.youtube" />
                  </div>
                  <div class="form-group mb">
                      <input type="text" placeholder="Instagram" v-model="venueInfo.socialLinks.instagram" />
                  </div>
                  <div class="form-group">
                      <input type="text" placeholder="Facebook" v-model="venueInfo.socialLinks.facebook" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PartnerApi from "@/services/partnerApi";

export default {
  name: 'VenueInfo',
  data() {
    return {
      title: 'Venue Information',
      venueInfo: {
        socialLinks: {
          twitter: '',
          youtube: '',
          instagram: '',
          facebook: '',
        }
      },
      regions: [],
      loading: false,
      partnerApi: null,
    }
  },
  metaInfo: {
    title: 'Venue Information'
  },
  computed: {
    ...mapGetters ([
      'getCountryList',
      'db',
      'getCurrentPartner'
    ])
  },

  components: {
  },

  async created() {
    let self = this;
    self.partnerApi = new PartnerApi();
    await self.getVenueInfo();
    self.selectedCountryRegions(self.venueInfo.country);
  },

  methods: {
    getVenueInfo: async function() {
      let self = this;
      self.loading = true;
      let venueInfo = await self.db.get();
      self.venueInfo = venueInfo.data().venueInfo;
      self.loading = false;
    },

    updateVenueInfo: async function(ref) {
      let self = this;
      let venueInfo = self.venueInfo;
      let success = await self.$refs.settingsForm.validate();
      if(success) {
        try {
          await self.db.update({ venueInfo });
          // await self.partnerApi.clearSiteDataCache(self.getCurrentPartner);
          self.$notify({
            group: 'saved',
            title: 'Venue Info Updated',
            type: 'success'
          });
        }
        catch (error){
          self.$notify({
            group: 'saved',
            title: 'Error Updating Venue Info',
            type: 'error'
          });
        }
      }
    },

    selectedCountryRegions: function (code) {
      let self = this;
      var country = self.getCountryList.find((country) => {
        if(code) {
          if (country.code === code.toUpperCase()) {
            return country;
          }
        }
      });

      if (country && country.regions) {
        self.regions = country.regions;
        return country.regions;
      }
      if (country && country.regionNames) {
        self.regions = country.regionNames;
        return country.regionNames;
      }
      return null;
    },

    resetRegion: function() {
      let self = this;
      self.regions = [];
      self.venueInfo.region = '';
      self.selectedCountryRegions(self.venueInfo.country);
    }

  },

  watch: {
    'db': function() {
      let self = this;
      self.getVenueInfo();
    }
  },


}
</script>
