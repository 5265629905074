<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.name">- {{ dataSet.name }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges(dataRef ? dataRef : dataSet.id)">Save Changes</button>

        <router-link :to="{ name: 'Venues' }" title="All Venues" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="dataForm">
          <div class="comic-form">

            <div class="form-group" v-if="!dataRef">
              <label for="address">Start Typing a Venue Name...<span class="req">*</span></label>

              <autocompleter
                  id="map"
                  placeholder="Enter the Venue Name"
                  types="establishment"
                  v-on:placechanged="setVenueInfo"
              >
              </autocompleter>
            </div>

            <div class="form-group">
              <label for="comicName">Name<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="comicName" v-model="dataSet.name" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="two-col-form">
              <div class="form-group">
                <label for="dataRef">ID<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="dataRef" v-model="dataSet.id" :disabled="dataRef" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="regionId">Region ID<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" placeholder="ex: ca-manitoba" id="regionId" v-model="dataSet.regionId" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="address1">Address<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="address1" v-model="dataSet.address1" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="address2">Address 2</label>
                <input type="text" id="address2" v-model="dataSet.address2" />
              </div>

              <div class="form-group">
                <label for="city">City<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="city" v-model="dataSet.city" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="stateName">State/Province Name<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="stateName" placeholder="ex: Manitoba" v-model="dataSet.stateName" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="stateCode">State/Province Code<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="stateName" v-model="dataSet.stateCode" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="countryName">Country Name<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="countryName" placeholder="ex: Canada" v-model="dataSet.countryName" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="countryCode">Country Code<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="countryCode" placeholder="ex: CA" v-model="dataSet.countryCode" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="zipPostal">Zip/Postal Code</label>
                <input type="text" id="zipPostal" v-model="dataSet.zipPostal" />
              </div>

              <div class="form-group">
                <label for="phoneNumber">Phone Number</label>
                <input type="text" id="phoneNumber" v-model="dataSet.phone" />
              </div>

              <div class="form-group">
                <label for="emailAddress">Email Address</label>
                <input type="text" id="emailAddress" v-model="dataSet.emailAddress" />
              </div>
            </div>

            <div class="form-group">
              <label for="venueDescription">Venue Description</label>
              <wysiwyg-editor
                id="venueDescription"
                v-model="dataSet.description"
                class="html-editor"
                :api-key="tinyApi"
                toolbar="undo redo | code | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                :init="{
                  menubar: false,
                  setup(editor) {
                    editor.on('init', function() {
                      editor.setContent(editor.getContent());
                    });
                  },
                  content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                }">
              </wysiwyg-editor>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Thumb Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="`${storageContext}/venues/${dataSet.id}/`" :oldImgUrl="dataSet.imageUrl"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Website &amp; Social Networks</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="website">Venue Website</label>
              <input type="text" id="website" v-model="dataSet.websiteLink" />
            </div>

            <div class="form-group">
              <label for="websiteEvents">Venue Events Page</label>
              <input type="text" id="websiteEvents" v-model="dataSet.websiteEventsLink" />
            </div>

            <div class="form-group">
              <label for="youtube">YouTube Featured Video</label>
              <input type="text" id="youtube" v-model="dataSet.youtubeLink" />
            </div>

            <div class="form-group">
              <label for="youtubeChannelId">YouTube Channel ID</label>
              <input type="text" id="youtubeChannelId" v-model="dataSet.youtubeChannelId" />
            </div>

            <div class="form-group">
              <label for="twitter">Twitter</label>
              <input type="text" id="twitter" v-model="dataSet.twitterLink" />
            </div>

            <div class="form-group">
              <label for="instagram">Instagram</label>
              <input type="text" id="instagram" v-model="dataSet.instagramLink" />
            </div>

            <div class="form-group">
              <label for="facebook">Facebook</label>
              <input type="text" id="facebook" v-model="dataSet.facebookLink" />
            </div>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Venue Amenities</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="foodAvailable">
                <input type="checkbox" v-model="dataSet.amenities.foodAvailable" id="foodAvailable" />
                Food Available
              </label>

              <label for="alcoholAvailable">
                <input type="checkbox" v-model="dataSet.amenities.alcoholAvailable" id="alcoholAvailable" />
                Alcohol Available
              </label>

              <label for="parking">
                <input type="checkbox" v-model="dataSet.amenities.parking" id="parking" />
                Parking On Site
              </label>

              <label for="hotelNearby">
                <input type="checkbox" v-model="dataSet.amenities.hotelNearby" id="hotelNearby" />
                Hotel Nearby
              </label>

              <label for="wheelchairAccessible">
                <input type="checkbox" v-model="dataSet.amenities.wheelchairAccessible" id="wheelchairAccessible" />
                Wheelchair Accessible
              </label>
            </div>

          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>SEO Settings</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="featuredVenue">
                <input type="checkbox" v-model="dataSet.featured" id="featuredVenue" />
                Feature this Venue
              </label>

              <label for="indexVenue">
                <input type="checkbox" v-model="dataSet.index" id="indexVenue" />
                Index this Venue Page
              </label>
            </div>

            <div class="form-group">
              <label for="seoTitle">Meta Title</label>
              <input type="text" id="seoTitle" v-model="dataSet.seoTitle" />
            </div>
            <div class="form-group">
              <label for="seoDescription">Meta Description</label>
              <textarea id="seoDescription" v-model="dataSet.seoDescription"></textarea>
            </div>
          </div>
        </div>

        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Venue
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Vue from 'vue'
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: 'Venue',
  data() {
    return {
      dataRef: this.$route.params.ref,
      venueList: [],
      dataSet: {
        address1: '',
        address2: '',
        amenities: {
          foodAvailable: false,
          alcoholAvailable: false,
          parking: false,
          hotelNearby: false,
          wheelchairAccessible: false,
        },
        city: '',
        countryCode: '',
        countryName: '',
        description: '',
        events: [],
        id: '',
        latitude: '',
        longitude: '',
        name: '',
        phone: '',
        regionId: '',
        stateCode: '',
        stateName: ''
      },
      loading: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Venue'
      } else {
        return 'Add a Venue'
      }
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader,
    'Autocompleter': VueGoogleAutocomplete
  },

  async created() {
    let self = this;

    if(self.dataRef) {
      self.getData();
    }

  },

  methods: {
    getVenues: async function() {
      let self = this;
      self.loading = true;

      let dataSet = await self.db.collection('venues').get();
      self.venuesList = dataSet.docs.map(doc => doc.data());
      self.loading = false;
    },


    getData: async function() {
      let self = this;
      self.loading = true;

      let dataSet = await self.db.collection('venues').doc(self.dataRef).get();
      self.dataSet = dataSet.data();

      if(!self.dataSet.amenities) {
        self.dataSet.amenities = {
          foodAvailable: false,
          alcoholAvailable: false,
          parking: false,
          hotelNearby: false,
          wheelchairAccessible: false,
        }
      }
      self.loading = false;
    },

    saveChanges: async function(ref) {
      let self = this;

      let success = await self.$refs.dataForm.validate();

      if(success) {
        if(self.dataRef) {
          try {
            await self.db.collection('venues').doc(ref).update(self.dataSet);
            let regionData = await self.saveVenueToRegion(true, self.dataSet.regionId, self.dataSet.city, self.dataSet.id, self.dataSet.name);
            await self.db.collection('regions').doc(self.dataSet.regionId).update(regionData);

            self.$notify({
              group: 'saved',
              title: 'Venue Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            let currentVenue = await self.db.collection('venues').doc(ref).get();

            if(!currentVenue.exists) {
              await self.db.collection('venues').doc(ref).set(self.dataSet);
              let regionData = await self.saveVenueToRegion(false, self.dataSet.regionId, self.dataSet.city, self.dataSet.id, self.dataSet.name);
              await self.db.collection('regions').doc(self.dataSet.regionId).update(regionData);

              self.$notify({
                group: 'saved',
                title: 'Venue Successfully Created',
                type: 'success'
              });
              await self.$router.push({ path: '/venues/' + ref });
              await self.getData();
            }
            else {
              self.$notify({
                group: 'saved',
                title: 'Error Creating Venue - There is already a Venue with that ID',
                type: 'error'
              });
            }
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Venue - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this venue?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              try {
                await self.db.collection('venues').doc(self.dataRef).delete();
                let regionData = await self.deleteVenueFromRegion(self.dataSet.regionId, self.dataSet.city, self.dataSet.id, self.dataSet.name);
                await self.db.collection('regions').doc(self.dataSet.regionId).update(regionData);

                self.$notify({
                  group: 'saved',
                  title: 'Venue Deleted',
                  type: 'success'
                });
                self.$modal.hide('dialog');
                self.$router.push({ name: 'Venues'} )
              }
              catch(error) {
                self.$notify({
                  group: 'saved',
                  title: 'Error Deleting - ' + error,
                  type: 'error'
                });
              }
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getThumbImgUrl (url) {
      let self = this;
      self.dataSet.imageUrl = url
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.dataSet.headerImageUrl = url
    },

    setVenueInfo: function (addressData, placeResultData, id) {
      let self = this;
      if(!self.dataRef) {  self.setId(placeResultData.name) }

      self.dataSet.name = placeResultData.name;
      self.dataSet.address1 = addressData.street_number + ' ' + addressData.route;
      self.dataSet.city = addressData.locality;
      self.dataSet.countryName = addressData.country;
      self.dataSet.stateCode = addressData.administrative_area_level_1;
      self.dataSet.zipPostal = addressData.postal_code;
      self.dataSet.latitude = addressData.latitude;
      self.dataSet.longitude = addressData.longitude;
    },

    saveVenueToRegion: async function(isUpdate, regionId, cityName, venueId, venueName) {
      let self = this;
      let regionData = await self.db.collection('regions').doc(regionId).get();
      regionData = regionData.data();

      let cityIndex = regionData.cities.findIndex(item => item.name.toLowerCase() === cityName.toLowerCase());

      if(cityIndex === -1) {
        let cityId = cityName.replace(/\s+/g, '-')
        cityId = cityName.replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase();

        let cityData = {
          content: "",
          name: cityName,
          id: cityId,
          countryCode: regionData.countryCode,
          stateCode: regionData.stateCode,
          venues: []
        }
        regionData.cities.push(cityData);
        cityIndex = regionData.cities.length -1;
      }

      if(isUpdate) {
        let venueIndex = regionData.cities[cityIndex].venues.findIndex(item => item.id === venueId);
        if(venueIndex !== -1) {
          regionData.cities[cityIndex].venues.splice(venueIndex, 1);
        }
      }

      let venueData = {
        'id': venueId,
        'name': venueName
      }

      regionData.cities[cityIndex].venues.push(venueData);
      return regionData;
    },

    deleteVenueFromRegion: async function(regionId, cityName, venueId, venueName) {
      let self = this;
      let regionData = await self.db.collection('regions').doc(regionId).get();
      regionData = regionData.data();

      let cityIndex = regionData.cities.findIndex(item => item.name === cityName);
      let venueIndex = regionData.cities[cityIndex].venues.findIndex(item => item.id === venueId);
      regionData.cities[cityIndex].venues.splice(venueIndex, 1);

      return regionData;
    },

    setId: function(value) {
      let self = this;
      let id = value.replace(/\s+/g, '-')
      id = id.replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase();
      self.$set(self.dataSet, 'id', id);
    },

  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Venues' });
    }
  }
}
</script>
