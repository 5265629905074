<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.name">- {{ dataSet.name }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges(dataRef ? dataRef : dataSet.id)">Save Changes</button>

        <router-link :to="{ name: 'Promotions' }" title="All Promotions" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="dataForm">
          <div class="comic-form">

            <div class="form-group">
              <label for="comicName">Title<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="comicName" v-model="dataSet.title" @input="setId($event.target.value)" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="dataRef">URL Slug<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="dataRef" v-model="dataSet.id" :disabled="dataRef" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="descriptionField">Description</label>
              <textarea rows="" cols="" id="descriptionField" v-model="dataSet.description"></textarea>
            </div>

            <div class="form-group">
              <label for="bodyContentField">Body Content</label>
              <wysiwyg-editor
                  id="bodyContentField"
                  v-model="dataSet.content"
                  class="html-editor page"
                  :api-key="tinyApi"
                  toolbar="undo redo | styleselect | bold italic underline forecolor | code | alignleft aligncenter alignright alignjustify | numlist bullist | fullscreen | link image media | preview"
                  plugins="autolink code fullscreen image link media template charmap anchor insertdatetime advlist lists wordcount imagetools textpattern noneditable preview"
                  :init="{
                    menubar: false,
                    inline: true,
                    setup: function (editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
              </wysiwyg-editor>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="sidebar-toggle">
        <button 
          @click="showSidebar = !showSidebar" 
          :title="showSidebar ? 'Hide Sidebar' : 'Show Sidebar'" 
          class="btn btn-text menu-toggle" 
          v-tooltip="showSidebar ? 'Hide Sidebar' : 'Show Sidebar'">
          <i class="fal fa-arrow-circle-right" v-if="showSidebar"></i>
          <i class="fal fa-arrow-circle-left" v-else></i>
        </button>
      </div>

      <div class="body-right one hideable" v-if="showSidebar">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Featured Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getImageUrl" :parentContext="`${storageContext}/promotions/${dataSet.id}/`" :oldImgUrl="dataSet.imageUrl"></file-uploader>
          </div>
        </div>


        <div class="content-panel">
          <div class="panel-title">
            <h3>Promotion Settings</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="featuredPromotion">
                <input type="checkbox" v-model="dataSet.featured" id="featuredPromotion" />
                Feature this Promotion
              </label>
            </div>

            <div class="form-group">
              <label for="seoTitle">Meta Title</label>
              <input type="text" id="seoTitle" v-model="dataSet.seoTitle" />
            </div>

            <div class="form-group">
              <label for="seoDescription">Meta Description</label>
              <textarea id="seoDescription" v-model="dataSet.seoDescription"></textarea>
            </div>
          </div>
        </div>

        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Promotion
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'

export default {
  name: 'Promotion',
  data() {
    return {
      dataRef: this.$route.params.ref,
      dataSet: { },
      loading: false,
      showSidebar: true
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Promotion'
      } else {
        return 'Add an Promotion'
      }
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader
  },

  async created() {
    let self = this;
    if(self.dataRef) {
      self.getData();
    }

  },

  methods: {
    getData: async function() {
      let self = this;
      self.loading = true;
      let dataSet = await self.db.collection('promotions').doc(self.dataRef).get();
      self.dataSet = dataSet.data();
      self.loading = false;
    },

    saveChanges: async function(ref) {
      let self = this;

      let success = await self.$refs.dataForm.validate();

      if(success) {
        if(self.dataRef) {
          try {
            await self.db.collection('promotions').doc(ref).update(self.dataSet);
            self.$notify({
              group: 'saved',
              title: 'Promotion Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            await self.db.collection('promotions').doc(ref).set(self.dataSet)
            self.$notify({
              group: 'saved',
              title: 'Promotion Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/promotions/' + ref });
            await self.getData();
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Promotion - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this comic?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('venues').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Promotion Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Promotions'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    setId: function(value) {
      let self = this;
      if(!self.dataRef) {
        let id = value.replace(/\s+/g, '-')
        id = id.replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase();
        self.$set(self.dataSet, 'id', id);
      }
    },

    getImageUrl: function (url) {
      let self = this;
      self.dataSet.imageUrl = url
    },

  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Promotions' });

    }
  }


}
</script>
