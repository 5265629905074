<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.name">- {{ dataSet.name }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges(dataRef ? dataRef : dataSet.id)">Save</button>

        <router-link :to="{ name: 'Products' }" title="All Products" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="productForm">
          <div class="event-form">

            <div class="form-section">
              <div class="form-group">
                <label for="eventName">Name<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="eventName" v-model="dataSet.name" @input="setId($event.target.value)" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group" v-if="!dataRef">
                <label for="dataRef">ID<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="dataRef" v-model="dataSet.id" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="eventDescription">Product Description</label>
                <wysiwyg-editor
                  id="eventDescription"
                  v-model="dataSet.description"
                  class="html-editor"
                  :api-key="tinyApi"
                  toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>

            <div class="form-section">
              <h3>Product Settings</h3>

              <div class="two-col-form">
                <div class="form-group">
                  <label for="serviceFee">Price</label>
                  <input type="number" id="serviceFee" v-model="dataSet.price" />
                </div>

                <div class="form-group">
                  <label for="serviceFee">Inventory</label>
                  <input type="number" id="serviceFee" v-model="dataSet.inventory" />
                </div>

                <div class="form-group">
                  <label for="serviceFee">Tax Rate</label>
                  <input type="number" id="serviceFee" v-model="dataSet.taxRatePercentage" />
                </div>

                <div class="form-group">
                  <label for="serviceFee">Service Fee</label>
                  <input type="number" id="serviceFee" v-model="dataSet.serviceFee" />

                  <div class="checkbox-group" v-if="dataSet.serviceFee">
                    <label for="applyServiceFee">
                      <input type="checkbox" v-model="dataSet.applyServiceFeePerItem" id="applyServiceFee" />
                      Apply Service Fee Per Item
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Thumb Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="`${storageContext}/products/${dataSet.id}/`" :oldImgUrl="dataSet.imageUrl"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>SEO Settings</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="featureProduct">
                <input type="checkbox" v-model="dataSet.featured" id="featureProduct" />
                Feature this Product
              </label>
            </div>

            <div class="form-group">
              <label for="seoTitle">Meta Title</label>
              <input type="text" id="seoTitle" v-model="dataSet.seoTitle" />
            </div>

            <div class="form-group">
              <label for="seoDescription">Meta Description</label>
              <textarea id="seoDescription" v-model="dataSet.seoDescription"></textarea>
            </div>
          </div>
        </div>

        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Product
          </button>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'

export default {
  name: 'Product',
  data() {
    return {
      dataRef: this.$route.params.ref,
      dataSet: { },
      productList: [],
      loading: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Product'
      } else {
        return 'Add an Product'
      }
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader
  },

  async created() {
    let self = this;
    if(self.dataRef) {
      self.getData();
    }

  },

  methods: {
    getData: async function() {
      let self = this;
      self.loading = true;

      let productsData = await self.db.collection('products').get();
      self.productList = productsData.docs.map(doc => doc.data());
      self.dataSet = self.productList.find(product => product.id === self.dataRef);

      self.loading = false;
    },

    saveChanges: async function(ref) {
      let self = this;
      let success = await self.$refs.productForm.validate();

      if(success) {

        self.dataSet.inventory = self.dataSet.inventory ? parseInt(self.dataSet.inventory) : 0;
        self.dataSet.price = self.dataSet.price ? parseFloat(self.dataSet.price) : 0;
        self.dataSet.serviceFee = self.dataSet.serviceFee ? parseFloat(self.dataSet.serviceFee) : 0;
        self.dataSet.taxRatePercentage = self.dataSet.taxRatePercentage ? parseFloat(self.dataSet.taxRatePercentage) : 0;

        if(self.dataRef) {
          try {
            await self.db.collection('products').doc(ref).update(self.dataSet);
            self.$notify({
              group: 'saved',
              title: 'Product Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            await self.db.collection('products').doc(ref).set(self.dataSet)
            self.$notify({
              group: 'saved',
              title: 'Product Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/products/' + ref });
            await self.getData();
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Product - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this product?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('products').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Product Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Products'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getThumbImgUrl (url) {
      let self = this;
      self.dataSet.imageUrl = url
    },

    setId: function(value) {
      let self = this;
      if(!self.dataRef) {
        let id = value.replace(/\s+/g, '-')
        id = id.replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase();
        self.$set(self.dataSet, 'id', id);
      }
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Products' });
    }
  }
}
</script>
