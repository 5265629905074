const axios = require('axios');

import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class PaymentApi {

  async processPayment(partnerId, amount, card, expiry, description) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/payment/process`, {partnerId, amount, card, expiry, description}, {headers});
  }
}
