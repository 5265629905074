<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'AddOrder' }" tag="button" class="btn" title="Add Order">
        <i class="fa-thin fa-plus-circle"></i>
        Add Order
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fa-thin fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Orders'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              @on-row-click="viewPage"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'orderDateTime', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">

                  <span v-if="props.column.field === 'orderDateTime'">
                    {{ props.row.orderDateTime | moment("ddd, MMM Do h:mma")}}
                  </span>

                  <span v-else-if="props.column.field === 'totalOrderAmount'">
                    ${{ props.row.totalOrderAmount }}
                  </span>

                  <span v-else-if="props.column.field === 'eventId'">
                    {{ getEventName(props.row.eventId) }}
                  </span>

                  <span v-else-if="props.column.field === 'showId'">
                    {{ getShowDate(props.row.eventId, props.row.showId) }}
                  </span>

                  <span v-else-if="props.column.field === 'orderNotes' && props.row.orderNotes">
                    <i class="fal fa-info-circle" v-tooltip="props.row.orderNotes"></i>
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Orders',
  data() {
    return {
      tableSearchTerm: '',
      eventRef: this.$route.query.event,
      columns: [
        {
          label: 'Status',
          field: 'status',
          tdClass: 'bold'
        },
        {
          label: 'ID',
          field: 'id',
          tdClass: 'bold'
        },
        {
          label: 'Date',
          field: 'orderDateTime',
          tdClass: 'fit-content'
        },
        {
          label: 'First Name',
          field: 'customerFirstName'
        },
        {
          label: 'Last Name',
          field: 'customerLastName'
        },
        {
          label: 'Card Number',
          field: 'creditCardNumber'
        },
        {
          label: 'Event',
          field: 'eventId'
        },
        {
          label: 'Show',
          field: 'showId'
        },
        {
          label: 'Tickets',
          field: 'numberOfTickets'
        },
        {
          label: 'Order Amount',
          field: 'totalOrderAmount'
        },
        {
          label: 'Notes',
          field: 'orderNotes',
          tdClass: 'text-right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getOrdersData',
        eventsList: 'getEventsData'
    }),

    title: function() {
      let self = this;
      return 'All Orders'
    },
  },

  metaInfo: {
    title: 'All Orders'
  },

  async mounted() {
    let self = this;
    self.getGridData();

    if(self.eventRef) {
      self.tableSearchTerm = self.eventRef;
    }
  },

  methods: {
    getGridData: async function(forceUpdate) {
      let self = this;

      if(!self.gridList.length || forceUpdate) {
        await self.$store.dispatch('bindOrdersData');
      }

      if(!self.eventsList.length || forceUpdate) {
        await self.$store.dispatch('bindEventsData');
      }
    },

    viewPage: function(params) {
      let self = this;
      let id = params.row.id;
      self.$router.push({name: 'EditOrder', params:{ref:id}})
    },

    getEvent: function(id) {
      let self = this;
      let event = self.eventsList.find(event => event.id === id);
      return event
    },

    getEventName: function(id) {
      let self = this;
      let event = self.eventsList.find(event => event.id === id);
      return event ? event.name : id
    },

    getShowDate: function(eventId, showId) {
      let self = this;
      let event = self.eventsList.find(event => event.id === eventId);
      let show;

      if (event) { 
        show = event.shows.find(show => show.id === showId) 
      }
      return show ? Vue.moment(show.date).format("ddd, MMM Do h:mma") : showId
    }
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  }
}
</script>
