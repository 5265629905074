const axios = require('axios');

import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class EmailApi {

  async sendTicketOrderEmail(partnerId, orderId) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/emails/sendTicketOrderEmail`, {partnerId, orderId}, {headers});
  }

  async sendProductOrderEmail(partnerId, orderId) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/emails/sendProductOrderEmail`, {partnerId, orderId}, {headers});
  }
}
