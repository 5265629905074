<template>
  <section class="page-body dashboard">
    <div class="page-title-bar">
      <h1>Dashboard</h1>
    </div>

    <div v-if="!loadingDashboard">
      <div class="content-panel">
        <div class="panel-title">
          <h3>Ticket Sales and Revenue: Last 7 Days</h3>
        </div>

        <div class="panel-body">
          <div class="dashboard-line-chart">
            <line-chart :chartData="chartData" :options="chartOptions" :styles="'height: 300px'"></line-chart>
          </div>

          <div class="line-chart-data">
            <div class="col" v-for="(item, itemIndex) in salesRevenueData" :key="itemIndex">
              <div class="item">
                <p class="date">{{ item.label }}</p>
                <p class="tickets"><i class="fal fa-ticket"></i> {{ item.ticketsSold }}</p>
                <p class="revenue">${{ item.revenue.toFixed(0) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="space-10"></div>

      <div class="content-panel">
        <div class="panel-title">
          <h3>Upcoming Events</h3>
        </div>

        <div class="panel-body">
          <div class="dashboard-featured-events">
            <div class="event-panel" v-for="(event, eventIndex) in featuredEvents.slice(0,4)" :key="eventIndex">

              <img :src="event.imageUrl" :alt="event.name" />

              <h4 class="name">{{ event.name.length > 25 ? event.name.substring(0, 25 - 3) + "..." : event.name.substring(0, 25) }}</h4>
              <p class="date">
                {{ event.startDate | moment("ddd, MMM Do") }}
                to {{ event.endDate | moment("ddd, MMM Do") }}
              </p>

              <div class="stats">
                <div class="item">
                  <p class="label">Today's Sales</p>
                  <h5>
                    <span>{{ event.ticketsSoldToday }} Tickets</span>
                    <span>${{ event.ticketsSoldTodayRevenue }}</span>
                  </h5>
                </div>

                <div class="flex-grid flex-thirds">
                  <div class="col item">
                    <p class="label">Sold</p>
                    <h5>{{event.ticketsSoldOverall}}</h5>
                  </div>

                  <div class="col item">
                    <p class="label">Avail.</p>
                    <h5>{{ event.ticketsAvailable }}</h5>
                  </div>

                  <div class="col item">
                    <p class="label">Total</p>
                    <h5>${{event.ticketsSoldOverallRevenue}}</h5>
                  </div>
                </div>
              </div>

              <div class="links">
                <router-link class="btn btn-sm btn-sec" :to="{ name: 'EditEvent', params:{ ref: event.id } }">View Event</router-link>
                <router-link class="btn btn-sm btn-sec" :to="{ name: 'Orders', query:{ event: event.id } }">View Orders</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-loading" v-if="loadingDashboard">
      <i class="fa-thin fa-spinner-third fa-spin"></i>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from '@/components/charts/LineChart';
import DashboardApi from "../services/admin/dashboardApi";

export default {
  name: 'Dashboard',
  data() {
    return {
      dashboardApi: new DashboardApi(),
      loadingDashboard: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          yAxes: [{
            ticks: {
                beginAtZero: true
            }
          }],
          xAxes: [{
            // display: false
          }]
        }
      },
      chartData: {
        labels: [],
        datasets: []
      }
    }
  },
  components: {
    LineChart
  },

  async mounted() {
    let self = this;
    self.loadingDashboard = true;
    let promises = [];
    promises.push(self.getSalesAndRevenueData());
    promises.push(self.getFeaturedEvents());
    await Promise.all(promises);
    self.loadingDashboard = false;
  },

  methods: {
    getSalesAndRevenueData: async function() {
      let self = this;
      let result;

      if(!self.salesRevenueData.length) {
        result = await self.dashboardApi.ticketSalesAndRevenueByDays(7);
        result = result.data;
        self.salesRevenueData = result;
        self.$store.dispatch('setDashboardSalesRevenueData', result);
      } else {
        result = self.salesRevenueData;
      }

      self.chartData = {
        labels: [],
        datasets: [
          {
            label: 'Ticket Sales',
            borderColor: '#EC008C',
            data: [],
            fill: false
          },
          {
            label: 'Revenue',
            borderColor: '#1B8AD9',
            data: [],
            fill: false
          }
        ]
      };

      result.forEach(item => {
        self.chartData.labels.push(item.label);
        self.chartData.datasets[0].data.push(item.ticketsSold);
        self.chartData.datasets[1].data.push(Math.round((item.revenue + Number.EPSILON)*100)/100);
      });
    },
    getFeaturedEvents: async function() {
      let self = this;
      if(!self.featuredEvents.length) {
        let result = await self.dashboardApi.upcomingEventsSalesSummary();
        self.featuredEvents = result.data;
        self.$store.dispatch('setDashboardFeaturedEvents', result.data);
      }
    }
  },

  computed: {
    ...mapGetters ({
      db : 'db',
      featuredEvents: 'getDashboardFeaturedEvents',
      salesRevenueData: 'getDashboardSalesRevenueData'
    }),
  },

  watch: {
    'db': async function() {
      let self = this;
      self.loadingDashboard = true;
      await self.getSalesAndRevenueData();
      await self.getFeaturedEvents();
      self.loadingDashboard = false;
    }
  }
}
</script>
