<template>
    <div class="content-panel flyout">
        <div class="panel-title">
            <h3>{{ title }}</h3>
            <a href="javascript:" @click="closeCancel()" title="Close Panel"><i class="close-flyout fal fa-times"></i></a>
        </div>

        <div class="panel-body">
            <form @submit.prevent="saveShow()">
                <ValidationObserver ref="flyoutForm">
                    <div class="form-group">
                        <label for="faqQuestion">Question<span class="req">*</span></label>
                        <validation-provider rules="required" v-slot="{ errors }">
                            <input type="text" id="faqQuestion" v-model="faq.question" />
                            <span class="invalid">{{ errors[0] }}</span>
                        </validation-provider>
                    </div>

                    <div class="form-group">
                        <label for="faqAnswer">Answer<span class="req">*</span></label>
                        <validation-provider rules="required" v-slot="{ errors }">
                            <wysiwyg-editor
                                id="faqAnswer"
                                v-model="faq.answer"
                                class="html-editor page"
                                :api-key="tinyApi"
                                toolbar="undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                                plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                                :init="{
                                    menubar: false,
                                    setup(editor) {
                                      editor.on('init', function() {
                                        editor.setContent(editor.getContent());
                                      });
                                    },
                                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                                }">
                            </wysiwyg-editor>
                            <span class="invalid">{{ errors[0] }}</span>
                        </validation-provider>
                    </div>
                </ValidationObserver>
            </form>
        </div>

        <div class="panel-footer btn-set">
            <button class="btn btn-lg" @click="saveFaq()" title="Save">Save</button>
            <button class="btn btn-text" @click="closeCancel()" title="Cancel">Cancel</button>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'EventShow',
    data() {
        return {
            faq: {},
            showFormErrors: false,
            isCurrentFaq: false,
            tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea'
        }
    },
    components: {
        'wysiwyg-editor': Editor
    },
    props: {
        flyoutData: Object,
        itemIndex: Number
    },
    computed: {
        title: function() {
            let self = this;
            if(self.isCurrentFaq) {
                return 'Edit FAQ'
            } else {
                return 'Add a New FAQ'
            }
        },
    },
    methods: {
        saveFaq: async function() {
            let self = this;
            let success = await self.$refs.flyoutForm.validate();

            if (success) {
                if(!self.isCurrentFaq) {
                    self.flyoutData.addFaqEventHandler(self.faq);
                }
                self.$emit('closePanel', {});
            } else {
                self.showFormErrors = true
            }
        },


        closeCancel: function() {
            let self = this;
            self.$emit('closePanel', {});
        }
    },
    created() {
        let self = this;
        if(self.itemIndex > -1) {
            self.isCurrentFaq = true;
            self.faq = self.flyoutData.faqs[self.itemIndex];
        }
    }
}
</script>
