import {firestoreAction} from "vuexfire";

const state = {
  dashboardFeaturedEvents: [],
  dashboardSalesRevenueData: [],
  eventsData: [],
  couponsData: [],
  comicsData: [],
  ordersData: [],
  productOrdersData: [],
  productsData: [],
  venuesData: [],
  pagesData: [],
  promotionsData: [],
  articlesData: [],
  contactEntriesData: [],
  emailSignupsData: []
}

const mutations = {
  setDashboardFeaturedEvents(context, data) {
    state.dashboardFeaturedEvents = data;
  },
  setDashboardSalesRevenueData(context, data) {
    state.dashboardSalesRevenueData = data;
  }
}

const getters = {
  getDashboardFeaturedEvents: state => state.dashboardFeaturedEvents,
  getDashboardSalesRevenueData: state => state.dashboardSalesRevenueData,
  getEventsData: state => state.eventsData,
  getComicsData: state => state.comicsData,
  getCouponsData: state => state.couponsData,
  getOrdersData: state => state.ordersData,
  getProductOrdersData: state => state.productOrdersData,
  getProductsData: state => state.productsData,
  getVenuesData: state => state.venuesData,
  getPagesData: state => state.pagesData,
  getPromotionsData: state => state.promotionsData,
  getArticlesData: state => state.articlesData,
  getContactEntriesData: state => state.contactEntriesData,
  getEmailSignupsData: state => state.emailSignupsData,
}

const actions = {
  unbindAllData: firestoreAction(({ unbindFirestoreRef }) => {
    state.dashboardFeaturedEvents = [];
    state.dashboardSalesRevenueData = [];
    unbindFirestoreRef('eventsData');
    unbindFirestoreRef('couponsData');
    unbindFirestoreRef('comicsData');
    unbindFirestoreRef('ordersData');
    unbindFirestoreRef('productOrdersData');
    unbindFirestoreRef('productsData');
    unbindFirestoreRef('venuesData');
    unbindFirestoreRef('pagesData');
    unbindFirestoreRef('promotionsData');
    unbindFirestoreRef('articlesData');
    unbindFirestoreRef('contactEntriesData');
    unbindFirestoreRef('emailSignupsData');
  }),

  setDashboardFeaturedEvents (context, data) {
    context.commit('setDashboardFeaturedEvents', data);
  },

  setDashboardSalesRevenueData (context, data) {
    context.commit('setDashboardSalesRevenueData', data);
  },

  bindEventsData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('eventsData', rootState.users.db.collection('events'))
  }),

  bindCouponsData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('couponsData', rootState.users.db.collection('coupons'))
  }),

  bindComicsData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('comicsData', rootState.users.db.collection('comedians'))
  }),

  bindOrdersData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    let d = new Date();
    return bindFirestoreRef('ordersData', rootState.users.db.collection('orders').where('orderDateTime', '>', new Date(d.setMonth(d.getMonth() - 6)).toISOString()))
  }),

  bindProductOrdersData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('productOrdersData', rootState.users.db.collection('productOrders'))
  }),

  bindProductsData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('productsData', rootState.users.db.collection('products'))
  }),

  bindVenuesData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('venuesData', rootState.users.db.collection('venues'))
  }),

  bindPagesData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('pagesData', rootState.users.db.collection('pages'))
  }),

  bindPromotionsData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('promotionsData', rootState.users.db.collection('promotions'))
  }),

  bindArticlesData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('articlesData', rootState.users.db.collection('articles'))
  }),

  bindContactEntriesData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('contactEntriesData', rootState.users.db.collection('contactEntries'))
  }),

  bindEmailSignupsData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('emailSignupsData', rootState.users.db.collection('emailSignups'))
  })
}

export default {
  state,
  mutations,
  getters,
  actions
}
