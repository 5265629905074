<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'AddProductOrder' }" tag="button" class="btn" title="Add Order">
        <i class="fa-thin fa-plus-circle"></i>
        Add Order
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fa-thin fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Orders'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              @on-row-click="viewPage"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'orderDateTime', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <a :title="props.row.id" v-if="props.column.field == 'id'">
                    <strong>{{ props.row.id }}</strong>
                  </a>

                  <span v-else-if="props.column.field === 'orderDateTime'">
                    {{ props.row.orderDateTime | moment("ddd, MMM Do, YYYY h:mma")}}
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProductOrders',
  data() {
    return {
      tableSearchTerm: '',
      columns: [
        {
          label: 'ID',
          field: 'id',
          tdClass: 'title-col',
          
        },
        {
          label: 'Order Date',
          field: 'orderDateTime'
        },
        {
          label: 'First Name',
          field: 'customerFirstName'
        },
        {
          label: 'Last Name',
          field: 'customerLastName'
        },
        {
          label: 'Card Number',
          field: 'cardNumber'
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getProductOrdersData'
    }),

    title: function() {
      let self = this;
      return 'All Product Orders'
    },

  },

  metaInfo: {
    title: 'All Product Orders'
  },

  async mounted() {
    let self = this;
    self.getGridData();
  },

  methods: {
    getGridData: async function(forceUpdate) {
      let self = this;

      if(!self.gridList.length || forceUpdate) {
        await self.$store.dispatch('bindProductOrdersData');
      }
    },

    viewPage: function(params) {
      let self = this;
      let id = params.row.id;

      self.$router.push({name: 'EditProductOrder', params:{ref:id}})
    }
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData();
    }
  }
}
</script>
