import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'

const config = {
  apiKey: "AIzaSyAuhAQxEiOD9U3r1hpjvxWzm3cnha6DQXo",
  authDomain: "watch-comedy-live.firebaseapp.com",
  projectId: "watch-comedy-live",
  storageBucket: "watch-comedy-live.appspot.com",
  messagingSenderId: "743081065784",
  appId: "1:743081065784:web:35e2bf1001c47c1bf055cd",
  measurementId: "G-JPXDWEWY10"
};

const firebaseApp = app.initializeApp(config);
export const fireStorage = firebaseApp.storage();
export const analytics = firebaseApp.analytics();
export const auth = firebaseApp.auth();
export const fs = firebaseApp.firestore();
