<template>
  <section class="page-body dashboard">
    <div class="page-title-bar">
      <h1>Social Media Marketing</h1>
    </div>

    <div class="body-flex">
      Coming Soon
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SocialMedia',
  data() {
    return {
      
    }
  },
  components: {
    
  },

  async mounted() {
    let self = this;
    
  },

  methods: {

  },

  computed: {
    ...mapGetters ([ 
    ]),
  },

  watch: {
  }
}
</script>
